import React, { Component } from 'react';
import SideBar from '../../components/global/Sidebar';
import TopBar from '../../components/global/Topbar';
import DataCard from '../../components/customer/dashboard/DataCard';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "redux";

class AdminDashboard extends Component {
    render() {
        return (
            <div>
                <title>Admin Dashboard</title>
                <div id="main-wrapper">
                    <TopBar
                        title="Dashboard"
                    />
                    <SideBar />
                    <div class="content-body">
                        <div class="container-fluid">
                            <h1>Changes To Be Done in Dashboard </h1>
                            {/* <div class="row">
                                <DataCard
                                    cardColor="primary"
                                    icon="zmdi-balance-wallet"
                                    iconColor="white"
                                    iconSize="zmdi-hc-3x"
                                    cardText="Wallet Balance"
                                    cardValue="5000"
                                />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => ({
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AdminDashboard);
