import fetch from 'isomorphic-fetch';
// const API_URL = `http://192.168.43.85:3333/api/v1/`;
export const API_URL = "https://api.ekrupay.in/api/v1/";

export default function downloadApi(name, endpoint, method = 'get', body) {

    console.log(`${API_URL}/${endpoint}`);
    return fetch(`${API_URL}/${endpoint}`, {
        headers: { 'content-type': 'application/json' },
        method,
        body: JSON.stringify(body),
    })
        .then(response => response.blob()).then(
            blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = name ? name : "venue-leads.csv";
                a.click();
            });
}
