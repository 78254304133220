import React, { Component } from 'react';
import SideBar from '../../components/global/Sidebar';
import TopBar from '../../components/global/Topbar';
import WalletCard from '../../components/merchant/wallets/WalletCard';
import apicaller from '../../util/ApiCaller';

import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "redux";
import { getUserData } from '../../modules/reducers';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import moment from 'moment';
import { numberFormat } from '../../App';
import swal from 'sweetalert';
document.addEventListener("wheel", function (event) {
    if (document.activeElement.type === "number") {
        document.activeElement.blur();
    }
});

class MerchantProfile extends Component {

    constructor() {
        super();
        this.state = {
            user: {},
            updateUser: {},
            updateWallet: {},
            walletUpdateType: '',
            isUpdatingWallet: false,
            isLightBoxOpen: false,
            selectedImage: '',
            editMode: false,
            isLoading: false,
            isUpdatingCharges: false,
            isUpdatingOrganizationLogo: false,
            isUpdatingCertificate: false,
            isUpdatingAadhar: false,
            isUpdatingPan: false,
            isUpdatingCancelledCheck: false,
            isUpdatingOrganizationImages: false,
            isUpdatingPassword: false,
            isUpdatingProfile: false
        }
    }


    componentDidMount() {
        let userId = this.props.match.params.id;
        this.fetchUserProfileDetails(userId);
    }

    getImage = async (fieldName, fileKey) => {
        await apicaller('get', `get/file?fileKey=${fileKey}`)
            .then(res => {
                if (res.status == 200) {
                    let user = this.state.user;
                    let buf = Buffer.from(res.data);
                    let base64 = buf.toString('base64');
                    let final_image = `data:image/jpeg;base64,${base64}`
                    user[fieldName] = final_image
                    this.setState({ user, updateUser: user })
                }
                else {
                    swal('Error', 'some internal issue', 'error');
                }
            })
    }

    downloadUserImages = async () => {
        if (this.state.user.organization_logo) {
            await this.getImage("organization_logo", this.state.user.organization_logo)
        }
        if (this.state.user.organization_inside_image) {
            await this.getImage("organization_inside_image", this.state.user.organization_inside_image)
        }
        if (this.state.user.organization_outside_image) {
            await this.getImage("organization_outside_image", this.state.user.organization_outside_image)
        }
        if (this.state.user.canceled_check) {
            await this.getImage("canceled_check", this.state.user.canceled_check)
        }

        if (this.state.user.pan_image) {
            await this.getImage("pan_image", this.state.user.pan_image)
        }
        if (this.state.user.aadhar_front_image) {
            await this.getImage("aadhar_front_image", this.state.user.aadhar_front_image)
        }
        if (this.state.user.aadhar_back_image) {
            await this.getImage("aadhar_back_image", this.state.user.aadhar_back_image)
        }
        if (this.state.user.certificate_image) {
            await this.getImage("certificate_image", this.state.user.certificate_image)
        }
    }

    fetchUserProfileDetails = (userId) => {
        apicaller('get', `user/${userId}/profile`)
            .then(res => {
                if (res.status == 200) {
                    if (res.data.data.user) {
                        this.setState({
                            user: res.data.data.user,
                            updateUser: res.data.data.user
                        }, () => {
                            this.downloadUserImages();
                        })
                    }
                    else {
                        swal('Error', 'some internal issue', 'error');
                    }
                }
                else {
                    swal('Error', 'some internal issue', 'error');
                }
            })
            .catch(err => {
                swal('Error', err.message, 'error');
            })
    }

    handleOnChangeFields = (userField, value) => {
        let updateUser = this.state.updateUser;
        updateUser[userField] = value
        this.setState({
            updateUser,
        })
    }

    handleOnUpdateWalletField = (field, value) => {
        let updateWallet = this.state.updateWallet;
        updateWallet[field] = value;
        this.setState({ updateWallet })
    }

    handleOnUpdateWalletType = (type) => {
        this.setState({
            walletUpdateType: type
        })
    }

    handleOnUpdateWallet = () => {
        if (this.state.updateWallet && parseInt(this.state.updateWallet.amount) > 0) {
            this.setState({
                isUpdatingWallet: true
            })
            let api_url = '';
            if (this.state.walletUpdateType === "debitWallet") {
                api_url = `admin/merchant/${this.state.user._id}/wallet/debit`;
            }
            else if (this.state.walletUpdateType === "debitPGWallet") {
                api_url = `admin/merchant/${this.state.user._id}/pg_wallet/debit`;
            }
            else if (this.state.walletUpdateType === "creditWallet") {
                api_url = `admin/merchant/${this.state.user._id}/wallet/credit`;
            }
            let data = {};
            data.amount = this.state.updateWallet.amount;
            data.notes = this.state.updateWallet.notes;
            console.log(data, api_url);
            apicaller('post', api_url, data)
                .then(res => {
                    if (res.status == 200) {
                        alert('Success', "Wallet updated successfully", 'success');
                        document.getElementById('wallet-update-model-close-button').click();
                        window.location.reload();
                    }
                    else {
                        swal('Error', 'some internal issue', 'error');
                        document.getElementById('wallet-update-model-close-button').click();
                    }
                    this.setState({
                        isUpdatingWallet: false
                    })
                })
                .catch(err => {
                    swal('Error', err.message, 'error');
                    this.setState({
                        isUpdatingWallet: false
                    })
                })
        }
        else {
            swal('Warning', 'Please enter amount greater then 0', 'warning');
        }
    }

    handleOnChangeMerchantChargesFields = (userField, value) => {
        let updateUser = this.state.updateUser;
        updateUser.merchant_charges[userField] = parseFloat(value);
        this.setState({
            updateUser,
        })
    }

    handleOnUpdateProfile = () => {
        let data = {};
        data.first_name = this.state.updateUser.first_name;
        data.last_name = this.state.updateUser.last_name;
        data.alternative_phone = this.state.updateUser.alternative_phone;
        data.shop_address = this.state.updateUser.shop_address;
        data.location = this.state.updateUser.location;
        data.account_number = this.state.updateUser.account_number;
        data.ifsc_code = this.state.updateUser.ifsc_code;
        data.account_holder_name = this.state.updateUser.account_holder_name;
        this.setState({
            isUpdatingProfile: true
        })
        apicaller('post', `user/${this.state.user._id}/profile/update`, data)
            .then(res => {
                if (res.status == 200) {
                    swal('Success', "user updated successfully", 'success');
                }
                else {
                    swal('Error', 'some internal issue', 'error');
                }
                this.setState({
                    isUpdatingProfile: false
                })
            })
            .catch(err => {
                swal('Error', err.message, 'error');
                this.setState({
                    isUpdatingProfile: false
                })
            })
    }

    handleOnUploadImage = (userField, files, fieldId) => {
        let value;
        for (var i in files) {
            if (i != "length" && i != "item") {
                var FileSize = files[i].size / 1024 / 1024; // in MB
                if (FileSize > 3) {
                    swal('Warning', 'File size exceeds 3 MB', 'warning');
                }
                value = files[i];
            }
        }
        if (fieldId) {
            document.getElementById(fieldId).textContent = value.name;
        }
        this.setState({
            [userField]: value,
        })
    }

    handleUpdateOrganizationLogo = () => {
        if (this.state.organization_logo) {
            if ((this.state.organization_logo.size / 1024 / 1024) < 3) {
                this.setState({
                    isUpdatingOrganizationLogo: true
                })
                let data = new FormData();
                data.append("organization_logo", this.state.organization_logo);
                apicaller('post', `user/${this.props.match.params.id}/profile/files/update`, data)
                    .then(res => {
                        if (res.status == 200) {
                            swal('Success', 'Logo updated Successfully', 'success');
                            document.getElementById('organization-logo-model-close-button').click();
                            this.fetchUserProfileDetails(this.props.match.params.id);
                        }
                        else {
                            swal('Error', 'some internal issue', 'error');
                        }
                        this.setState({
                            isUpdatingOrganizationLogo: false
                        })
                    })
                    .catch(err => {
                        swal('Error', err.message, 'error');
                        this.setState({
                            isUpdatingOrganizationLogo: false
                        })
                    })
            }
            else {
                swal('Warning', 'Merchant logo should be less than 3MB', 'warning')
            }
        }
        else {
            swal('Warning', 'Merchant logo is required', 'warning')
        }
    }

    validateUpdatePassword = (input) => {
        let output = {};
        output.isValid = true;
        output.message = "";
        if (!input.password || input.new_password == "") {
            output.isValid = false;
            output.message = "Please enter new password";
        }
        if (!input.confirm_new_password || input.confirm_new_password == "") {
            output.isValid = false;
            output.message = "Please enter confirm password";
        }
        if (input.password != input.confirm_new_password) {
            output.isValid = false;
            output.message = "Confirm password is incorrect";
        }
        return output;
    }

    handleOnUpdatePassword = () => {
        let data = {};
        data.password = this.state.updateUser.new_password;
        data.confirm_new_password = this.state.updateUser.confirm_new_password;
        let validation = this.validateUpdatePassword(data);
        if (validation.isValid) {
            this.setState({
                isUpdatingPassword: true
            })
            apicaller('post', `user/${this.state.user._id}/profile/update`, data)
                .then(res => {
                    if (res.status == 200) {
                        if (res.data.data.user) {
                            swal('Success', 'password updated successfully', 'success');
                        }
                        else {
                            swal('Error', res.data.data.message, 'error');
                        }
                        document.getElementById('merchant-password-model-close-button').click();
                    }
                    else {
                        swal('Error', 'some internal issue', 'error');
                    }
                    this.setState({
                        isUpdatingPassword: false
                    })
                })
                .catch(err => {
                    swal('Error', err.message, 'error');
                    this.setState({
                        isUpdatingPassword: false
                    })
                })
        }
        else {
            swal('Warning', validation.message, 'warning');
        }
        this.setState({
            isLoading: false
        })
    }

    handleUpdateOrganizationImages = () => {
        if (this.state.organization_inside_image && this.state.organization_outside_image) {
            if ((this.state.organization_inside_image.size / 1024 / 1024) < 3 && (this.state.organization_outside_image.size / 1024 / 1024) < 3) {
                this.setState({
                    isUpdatingOrganizationImages: true
                })
                let data = new FormData();
                data.append("organization_inside_image", this.state.organization_inside_image);
                data.append("organization_outside_image", this.state.organization_outside_image);
                apicaller('post', `user/${this.props.match.params.id}/profile/files/update`, data)
                    .then(res => {
                        if (res.status == 200) {
                            swal('Success', 'Organization images updated Successfully', 'success');
                            document.getElementById('update-organization-model-close-button').click();
                            this.fetchUserProfileDetails(this.props.match.params.id);
                        }
                        else {
                            swal('Error', 'some internal issue', 'error');
                        }
                        this.setState({
                            isUpdatingOrganizationImages: false
                        })
                    })
                    .catch(err => {
                        swal('Error', err.message, 'error');
                        this.setState({
                            isUpdatingOrganizationImages: false
                        })
                    })
            }
            else {
                swal('Warning', 'File should be less than 3MB', 'warning')
            }
        }
        else {
            swal('Warning', 'Shop inside photo and shop outside photo are required', 'warning')
        }
    }

    handleUpdateCanceledCheck = () => {
        if (this.state.canceled_check) {
            if ((this.state.canceled_check.size / 1024 / 1024) < 3) {
                this.setState({
                    isUpdatingCancelledCheck: true
                })
                let data = new FormData();
                data.append("canceled_check", this.state.canceled_check);
                apicaller('post', `user/${this.props.match.params.id}/profile/files/update`, data)
                    .then(res => {
                        if (res.status == 200) {
                            swal('Success', 'Cancelled Check updated Successfully', 'success');
                            document.getElementById('update-cancelled-check-model-close-button').click();
                            this.fetchUserProfileDetails(this.props.match.params.id);
                        }
                        else {
                            swal('Error', 'some internal issue', 'error');
                        }
                        this.setState({
                            isUpdatingCancelledCheck: false
                        })
                    })
                    .catch(err => {
                        swal('Error', err.message, 'error');
                        this.setState({
                            isUpdatingCancelledCheck: false
                        })
                    })
            }
            else {
                swal('Warning', 'Cancelled check should be less than 3MB', 'warning')
            }
        }
        else {
            swal('Warning', 'Cancelled check is required', 'warning')
        }
    }

    handleUpdatePanCard = () => {
        if (this.state.pan_image) {
            if ((this.state.pan_image.size / 1024 / 1024) < 3) {
                this.setState({
                    isUpdatingPan: true
                })
                let data = new FormData();
                data.append("pan_image", this.state.pan_image);
                apicaller('post', `user/${this.props.match.params.id}/profile/files/update`, data)
                    .then(res => {
                        if (res.status == 200) {
                            swal('Success', 'Pan card updated Successfully', 'success');
                            document.getElementById('update-pan-model-close-button').click();
                            this.fetchUserProfileDetails(this.props.match.params.id);
                        }
                        else {
                            swal('Error', 'some internal issue', 'error');
                        }
                        this.setState({
                            isUpdatingPan: false
                        })
                    })
                    .catch(err => {
                        swal('Error', err.message, 'error');
                        this.setState({
                            isUpdatingPan: false
                        })
                    })
            }
            else {
                swal('Warning', 'Pan Image should be less than 3MB', 'warning')
            }
        }
        else {
            swal('Warning', 'Pan Image is required', 'warning')
        }
    }

    handleUpdateAadhaarCard = () => {
        if (this.state.aadhar_front_image && this.state.aadhar_back_image) {
            if ((this.state.aadhar_front_image.size / 1024 / 1024) < 3 && (this.state.aadhar_back_image.size / 1024 / 1024) < 3) {
                this.setState({
                    isUpdatingAadhar: true
                })
                let data = new FormData();
                data.append("aadhar_front_image", this.state.aadhar_front_image);
                data.append("aadhar_back_image", this.state.aadhar_back_image);
                apicaller('post', `user/${this.props.match.params.id}/profile/files/update`, data)
                    .then(res => {
                        if (res.status == 200) {
                            swal('Success', 'Aadhar card updated Successfully', 'success');
                            document.getElementById('update-aadhar-model-close-button').click();
                            this.fetchUserProfileDetails(this.props.match.params.id);
                        }
                        else {
                            swal('Error', 'some internal issue', 'error');
                        }
                        this.setState({
                            isUpdatingAadhar: false
                        })
                    })
                    .catch(err => {
                        swal('Error', err.message, 'error');
                        this.setState({
                            isUpdatingAadhar: false
                        })
                    })
            }
            else {
                swal('Warning', 'File should be less than 3MB', 'warning')
            }
        }
        else {
            swal('Warning', 'Aadhar Front and Aadhar Back photo are required', 'warning')
        }
    }

    handleUpdateCertificateImage = () => {
        if (this.state.certificate_image) {
            if ((this.state.certificate_image.size / 1024 / 1024) < 3) {
                this.setState({
                    isUpdatingCertificate: true
                })
                let data = new FormData();
                data.append("certificate_image", this.state.certificate_image);
                apicaller('post', `user/${this.props.match.params.id}/profile/files/update`, data)
                    .then(res => {
                        if (res.status == 200) {
                            swal('Success', 'Certificate updated Successfully', 'success');
                            document.getElementById('update-certificate-model-close-button').click();
                            this.fetchUserProfileDetails(this.props.match.params.id);
                        }
                        else {
                            swal('Error', 'some internal issue', 'error');
                        }
                        this.setState({
                            isUpdatingCertificate: false
                        })
                    })
                    .catch(err => {
                        swal('Error', err.message, 'error');
                        this.setState({
                            isUpdatingCertificate: false
                        })
                    })
            }
            else {
                swal('Warning', 'Certificate should be less than 3MB', 'warning')
            }
        }
        else {
            swal('Warning', 'Certificate is required', 'warning')
        }
    }

    validateCharges = (input) => {
        let output = {};
        output.isValid = true;
        output.message = '';
        if (input.creditCard == null || input.creditCard < 0) {
            output.isValid = false;
            output.message = 'Credit card charges are required';
            return output;
        }
        if (input.debitCard == null || input.debitCard < 0) {
            output.isValid = false;
            output.message = 'Debit card charges are required';
            return output;
        }
        if (input.netBanking == null || input.netBanking < 0) {
            output.isValid = false;
            output.message = 'Net Banking charges are required';
            return output;
        }
        // if (!input.upi || input.upi < 0) {
        //     output.isValid = false;
        //     output.message = 'UPI charges are required';
        //     return output;
        // }
        if (input.wallet == null || input.wallet < 0) {
            output.isValid = false;
            output.message = 'Wallet charges are required';
            return output;
        }
        if (input.amexCard == null || input.amexCard < 0) {
            output.isValid = false;
            output.message = 'Amex card charges are required';
            return output;
        }
        if (input.pgWalletToWallet == null || input.pgWalletToWallet < 0) {
            output.isValid = false;
            output.message = 'Insta Settlement charges are required';
            return output;
        }
        if (input.dmr == null || input.dmr < 0) {
            output.isValid = false;
            output.message = 'DMR charges are required';
            return output;
        }
        if (input.rentalBilling == null || input.rentalBilling < 0) {
            output.isValid = false;
            output.message = 'Rental Billing charges are required';
            return output;
        }
        if (input.institutionalBilling == null || input.institutionalBilling < 0) {
            output.isValid = false;
            output.message = 'Institutional Billing charges are required';
            return output;
        }
        if (input.groceryBilling == null || input.groceryBilling < 0) {
            output.isValid = false;
            output.message = 'Grocery Billing charges are required';
            return output;
        }
        return output;
    }

    handleOnUpdateCharges = () => {
        let data = {
            "creditCard": this.state.updateUser.merchant_charges.creditCard,
            "debitCard": this.state.updateUser.merchant_charges.debitCard,
            "prepaidCard": this.state.updateUser.merchant_charges.prepaidCard,
            "netBanking": this.state.updateUser.merchant_charges.netBanking,
            "upi": this.state.updateUser.merchant_charges.upi,
            "wallet": this.state.updateUser.merchant_charges.wallet,
            "amexCard": this.state.updateUser.merchant_charges.amexCard,
            "pgWalletToWallet": this.state.updateUser.merchant_charges.pgWalletToWallet,
            "dmr": this.state.updateUser.merchant_charges.dmr,
            "rentalBilling": this.state.updateUser.merchant_charges.rentalBilling,
            "institutionalBilling": this.state.updateUser.merchant_charges.institutionalBilling,
            "groceryBilling": this.state.updateUser.merchant_charges.groceryBilling,
        };
        let validation = this.validateCharges(data);
        if (validation.isValid) {
            this.setState({
                isUpdatingCharges: true
            })
            apicaller('post', `admin/merchant-charges/${this.state.updateUser.merchant_charges._id}/update`, data)
                .then(res => {
                    if (res.status == 200) {
                        if (res.data.data.merchantCharges) {
                            swal('Success', 'Charges updated successfully.', 'success');
                            this.setState({
                                editMode: false
                            })
                        }
                        else {
                            swal('Error', 'some internal issue', 'error');
                        }
                    }
                    else {
                        swal('Error', 'some internal issue', 'error');
                    }
                    this.setState({
                        isUpdatingCharges: false
                    })
                })
                .catch(err => {
                    swal('Error', err.message, 'error');
                    this.setState({
                        isUpdatingCharges: false
                    })
                })
        }
        else {
            swal('Warning', validation.message, 'warning');
        }
    }

    handleOnUpdateServices = () => {
        let data = {};
        data.isRentalServiceAllowed = this.state.updateUser.isRentalServiceAllowed;
        data.isInstitutionalServiceAllowed = this.state.updateUser.isInstitutionalServiceAllowed;
        data.isGroceryServiceAllowed = this.state.updateUser.isGroceryServiceAllowed;
        this.setState({
            isLoading: true
        })
        apicaller('post', `user/${this.state.user._id}/profile/update`, data)
            .then(res => {
                if (res.status == 200) {
                    swal('Success', "user services updated successfully", 'success');
                }
                else {
                    swal('Error', 'some internal issue', 'error');
                }
                this.setState({
                    isLoading: false,
                    editMode: false
                })
            })
            .catch(err => {
                swal('Error', err.message, 'error');
                this.setState({
                    isLoading: false,
                    editMode: false
                })
            })
    }

    render() {
        return (
            <div>
                <title>Profile</title>
                <div id="main-wrapper">
                    <TopBar
                        title="Profile"
                    />
                    <SideBar />
                    <div class="content-body">
                        <div class="container-fluid">
                            <div className="row">
                                <div className="col-xl-8">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="profile-tab">
                                                <div className="custom-tab-1">
                                                    <ul className="nav nav-tabs">
                                                        <li className="nav-item"><a href="#view-profile" data-toggle="tab" className="nav-link active show">View Profile</a>
                                                        </li>
                                                        <li className="nav-item"><a href="#edit-profile" data-toggle="tab" className="nav-link">Edit Profile</a>
                                                        </li>
                                                        <li className="nav-item"><a href="#edit-charges" data-toggle="tab" className="nav-link">Merchant Charges</a>
                                                        </li>
                                                        <li className="nav-item"><a href="#edit-services" data-toggle="tab" className="nav-link">Services</a>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content">
                                                        <div id="view-profile" className="tab-pane fade active show">
                                                            <div className="mt-4">
                                                                <h4 className="mb-3">Basic Details</h4>
                                                                <section className="ml-3">
                                                                    <div className="row">
                                                                        <div className="col-xl-12 col-sm-12 mb-2">
                                                                            <h5 className="f-w-500">Organization Name</h5>
                                                                            <span>{this.state.user.organization_name}</span>
                                                                        </div>
                                                                        <div className="col-xl-4 col-sm-4 mb-2">
                                                                            <h5 className="f-w-500">Proprietor First Name</h5>
                                                                            <span>{this.state.user.first_name}</span>
                                                                        </div>
                                                                        <div className="col-xl-4 col-sm-4 mb-2">
                                                                            <h5 className="f-w-500">Proprietor Last Name</h5>
                                                                            <span>{this.state.user.last_name}</span>
                                                                        </div>
                                                                        <div className="col-xl-4 col-sm-4 mb-2">
                                                                            <h5 className="f-w-500">Phone Number</h5>
                                                                            <span>{this.state.user.phone}</span>
                                                                        </div>
                                                                        <div className="col-xl-4 col-sm-4 mb-2">
                                                                            <h5 className="f-w-500">Alternate Phone Number</h5>
                                                                            <span>{this.state.user.alternative_phone}</span>
                                                                        </div>
                                                                        <div className="col-xl-4 col-sm-4 mb-2">
                                                                            <h5 className="f-w-500">Email</h5>
                                                                            <span>{this.state.user.email}</span>
                                                                        </div>
                                                                        <div className="col-xl-4 col-sm-4 mb-2">
                                                                            <h5 className="f-w-500">Date of Birth</h5>
                                                                            <span>{this.state.user.dob}</span>
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                                <h4 className="mt-4 mb-3">Shop Details</h4>
                                                                <section className="ml-3">
                                                                    <div className="row">
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">Shop Address</h5>
                                                                            <span>{this.state.user.shop_address}</span>
                                                                        </div>
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">Location</h5>
                                                                            <span>{this.state.user.location}</span>
                                                                        </div>
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">Logo of Merchant <i class="zmdi zmdi-edit" data-toggle="modal" data-target="#updateLogoModal" style={{ color: "#40189D", fontSize: 20, paddingLeft: 15, marginTop: 10 }}></i></h5>
                                                                            <img src={this.state.user.organization_logo} onClick={() => this.setState({ isLightBoxOpen: true, selectedImage: this.state.user.organization_logo })} alt="" className="mt-2 mb-2 w-25" />
                                                                        </div>
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">Shop Photos <i class="zmdi zmdi-edit" data-toggle="modal" data-target="#updateShopPhotosModal" style={{ color: "#40189D", fontSize: 20, paddingLeft: 15, marginTop: 10 }}></i></h5>
                                                                            <img src={this.state.user.organization_outside_image} onClick={() => this.setState({ isLightBoxOpen: true, selectedImage: this.state.user.organization_outside_image })} alt="" height="80%" width="40%" className="mt-2 mr-2 mb-2" />
                                                                            <img src={this.state.user.organization_inside_image} onClick={() => this.setState({ isLightBoxOpen: true, selectedImage: this.state.user.organization_inside_image })} alt="" height="80%" width="40%" className="mt-2 mb-2" />
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                                <h4 className="mt-4 mb-3">Bank Details</h4>
                                                                <section className="ml-3">
                                                                    <div className="row">
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">Account Number</h5>
                                                                            <span>{this.state.user.account_number}</span>
                                                                        </div>
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">IFSC Code</h5>
                                                                            <span>{this.state.user.ifsc_code}</span>
                                                                        </div>
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">Account Holder Name</h5>
                                                                            <span>{this.state.user.account_holder_name}</span>
                                                                        </div>
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">Canceled cheque <i class="zmdi zmdi-edit" data-toggle="modal" data-target="#updateCanceledCheck" style={{ color: "#40189D", fontSize: 20, paddingLeft: 15, marginTop: 10 }}></i></h5>
                                                                            <img src={this.state.user.canceled_check} onClick={() => this.setState({ isLightBoxOpen: true, selectedImage: this.state.user.canceled_check })} height="80%" width="40%" alt="" className="mt-2 mb-2 w-50" />
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                                <h4 className="mt-4 mb-3">Documents uploaded</h4>
                                                                <section className="ml-3">
                                                                    <div className="row">
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">PAN Card Number</h5>
                                                                            <span>{this.state.user.pan_number}</span>
                                                                        </div>
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">PAN Card <i class="zmdi zmdi-edit" data-toggle="modal" data-target="#updatePanCard" style={{ color: "#40189D", fontSize: 20, paddingLeft: 15, marginTop: 10 }}></i></h5>
                                                                            <img src={this.state.user.pan_image} onClick={() => this.setState({ isLightBoxOpen: true, selectedImage: this.state.user.pan_image })} height="80%" width="40%" v alt="" className="mt-2 mb-2 w-50" />
                                                                        </div>
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">Aadhaar Card Number</h5>
                                                                            <span>{this.state.user.aadhar_number}</span>
                                                                        </div>
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">Aadhaar Card Front <i class="zmdi zmdi-edit" data-toggle="modal" data-target="#updateAadharCard" style={{ color: "#40189D", fontSize: 20, paddingLeft: 15, marginTop: 10 }}></i></h5>
                                                                            <img src={this.state.user.aadhar_front_image} onClick={() => this.setState({ isLightBoxOpen: true, selectedImage: this.state.user.aadhar_front_image })} height="80%" width="40%" alt="" className="mt-2 mb-2 w-50" />
                                                                        </div>
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">Aadhaar Card Back</h5>
                                                                            <img src={this.state.user.aadhar_back_image} onClick={() => this.setState({ isLightBoxOpen: true, selectedImage: this.state.user.aadhar_back_image })} height="80%" width="40%" alt="" className="mt-2 mb-2 w-50" />
                                                                        </div>
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">Certificates  <i class="zmdi zmdi-edit" data-toggle="modal" data-target="#updateCertificateImage" style={{ color: "#40189D", fontSize: 20, paddingLeft: 15, marginTop: 10 }}></i></h5>
                                                                            <img src={this.state.user.certificate_image} onClick={() => this.setState({ isLightBoxOpen: true, selectedImage: this.state.user.certificate_image })} height="80%" width="40%" alt="" className="mt-2 mb-2 w-50" />
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>
                                                        <div id="edit-profile" className="tab-pane fade">
                                                            <form action="#" id="" className="step-form-horizontal mt-4">
                                                                <div>
                                                                    <h4 className="form_title">Basic Details</h4>
                                                                    <section>
                                                                        <div className="row">
                                                                            <div className="col-lg-12 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Organization Name*</label>
                                                                                    <input type="text" name="organization_name" className="form-control" onChange={(e) => this.handleOnChangeFields("organization_name", e.target.value)} value={this.state.updateUser.organization_name} placeholder="Enter organization Name required" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Proprietor First Name*</label>
                                                                                    <input type="text" name="firstName" className="form-control" onChange={(e) => this.handleOnChangeFields("first_name", e.target.value)} value={this.state.updateUser.first_name} placeholder="Proprietor First Name" required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Proprietor Last Name*</label>
                                                                                    <input type="text" name="lastName" className="form-control" onChange={(e) => this.handleOnChangeFields("last_name", e.target.value)} value={this.state.updateUser.last_name} placeholder="Proprietor Last Name" required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Alternate Phone Number*</label>
                                                                                    <input type="text" name="alternatePhoneNumber" className="form-control" onChange={(e) => this.handleOnChangeFields("alternative_phone", e.target.value)} value={this.state.updateUser.alternative_phone} placeholder="Enter Alternate Phone Number" required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Date of Birth*</label>
                                                                                    <input type="date" name="dob" className="form-control" value={moment(new Date(this.state.updateUser.dob)).format("YYYY-MM-DD")} onChange={(e) => this.handleOnChangeFields("dob", e.target.value)} placeholder="Enter Date of Birth" required />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                    <h4>Shop Details</h4>
                                                                    <section>
                                                                        <div className="row">
                                                                            <div className="col-lg-12 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Shop Address*</label>
                                                                                    <input type="text" name="shopAddress" className="form-control" onChange={(e) => this.handleOnChangeFields("shop_address", e.target.value)} value={this.state.updateUser.shop_address} placeholder="Enter Shop Address" required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-12 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Location*</label>
                                                                                    <input type="text" className="form-control" onChange={(e) => this.handleOnChangeFields("location", e.target.value)} value={this.state.updateUser.location} placeholder="Enter Location" required />
                                                                                </div>
                                                                            </div>
                                                                            {/* <div className="col-lg-12 mb-3">
                                                                                <label className="text-label">Logo of Merchant*</label>
                                                                                <div className="input-group">
                                                                                    <div className="custom-file">
                                                                                        <input type="file" className="custom-file-input" required />
                                                                                        <label className="custom-file-label">Choose file</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                                                        </div>
                                                                    </section>
                                                                    <h4>Bank Details</h4>
                                                                    <section>
                                                                        <div className="row">
                                                                            <div className="col-lg-12 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Account Number*</label>
                                                                                    <input type="text" name="accountNumber" className="form-control" onChange={(e) => this.handleOnChangeFields("account_number", e.target.value)} value={this.state.updateUser.account_number} placeholder="Enter Account Number" required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-12 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">IFSC Code*</label>
                                                                                    <input type="text" name="ifscCode" className="form-control" onChange={(e) => this.handleOnChangeFields("ifsc_code", e.target.value)} value={this.state.updateUser.ifsc_code} placeholder="Enter IFSC Code" required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-12 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Account Holder Name*</label>
                                                                                    <input type="text" className="form-control" onChange={(e) => this.handleOnChangeFields("account_holder_name", e.target.value)} value={this.state.updateUser.account_holder_name} placeholder="Enter Account Holder Name" required />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                    <h4>KYC Details</h4>
                                                                    <section>
                                                                        <div className="row">
                                                                            <div className="col-lg-12 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Pan Number*</label>
                                                                                    <input type="text" name="pan" className="form-control" onChange={(e) => this.handleOnChangeFields("pan_number", e.target.value)} value={this.state.updateUser.pan_number} placeholder="Enter Pan Number" required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-12 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Aadhar Number*</label>
                                                                                    <input type="text" name="aadhar_number" className="form-control" onChange={(e) => this.handleOnChangeFields("aadhar_number", e.target.value)} value={this.state.updateUser.aadhar_number} placeholder="Enter Aadhar card Number" required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-12 mt-2 mb-2 text-center">
                                                                                <button type="button" onClick={this.handleOnUpdateProfile} className="btn btn-primary btn-block" disabled={this.state.isUpdatingProfile}>{this.state.isUpdatingProfile ? "Updating..." : "Update"}</button>
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                            </form>
                                                        </div>
                                                        <div id="edit-charges" className="tab-pane fade">
                                                            <form action="#" id="" className="step-form-horizontal mt-4">
                                                                <div>
                                                                    <h4 className="form_title">View / Edit Merchant Charges  {!this.state.editMode ? <i class="zmdi zmdi-edit" onClick={() => { this.setState({ editMode: true }) }} style={{ color: "#40189D", fontSize: 20, paddingLeft: 15, marginTop: 10 }}></i> : null}</h4>
                                                                    <section>
                                                                        <div className="row">
                                                                            <div className="col-lg-6 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Credit Card Charges*</label>
                                                                                    <input type="number" name="creditCard" min='0' className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("creditCard", e.target.value)} value={this.state.updateUser.merchant_charges ? this.state.updateUser.merchant_charges.creditCard : null} disabled={!this.state.editMode} required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Debit Card Charges*</label>
                                                                                    <input type="number" name="debitCard" min='0' className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("debitCard", e.target.value)} value={this.state.updateUser.merchant_charges ? this.state.updateUser.merchant_charges.debitCard : null} disabled={!this.state.editMode} required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Prepaid Card Charges*</label>
                                                                                    <input type="number" name="prepaidCard" min='0' className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("prepaidCard", e.target.value)} value={this.state.updateUser.merchant_charges ? this.state.updateUser.merchant_charges.prepaidCard : null} disabled={!this.state.editMode} required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Net Banking Charges*</label>
                                                                                    <input type="number" name="netBanking" min='0' className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("netBanking", e.target.value)} value={this.state.updateUser.merchant_charges ? this.state.updateUser.merchant_charges.netBanking : null} disabled={!this.state.editMode} required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">UPI Charges*</label>
                                                                                    <input type="number" name="upi" min='0' className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("upi", e.target.value)} value={this.state.updateUser.merchant_charges ? this.state.updateUser.merchant_charges.upi : null} disabled={!this.state.editMode} required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Wallet Charges*</label>
                                                                                    <input type="number" name="wallet" min='0' className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("wallet", e.target.value)} value={this.state.updateUser.merchant_charges ? this.state.updateUser.merchant_charges.wallet : null} disabled={!this.state.editMode} required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">AMEX Card Charges*</label>
                                                                                    <input type="number" name="amexCard" min='0' className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("amexCard", e.target.value)} value={this.state.updateUser.merchant_charges ? this.state.updateUser.merchant_charges.amexCard : null} disabled={!this.state.editMode} required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Insta Settlement Charges*</label>
                                                                                    <input type="number" name="pgWalletToWallet" min='0' className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("pgWalletToWallet", e.target.value)} value={this.state.updateUser.merchant_charges ? this.state.updateUser.merchant_charges.pgWalletToWallet : null} disabled={!this.state.editMode} required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">DMR Charges*</label>
                                                                                    <input type="number" name="dmr" min='0' className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("dmr", e.target.value)} value={this.state.updateUser.merchant_charges ? this.state.updateUser.merchant_charges.dmr : null} disabled={!this.state.editMode} required />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <h3>Service Charges</h3>
                                                                        <div className="row">
                                                                            <div className="col-lg-6 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Rental Billing Charges*</label>
                                                                                    <input type="number" name="rb" min='0' className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("rentalBilling", e.target.value)} value={this.state.updateUser.merchant_charges ? this.state.updateUser.merchant_charges.rentalBilling : null} disabled={!this.state.editMode} required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Institutional Billing Charges*</label>
                                                                                    <input type="number" name="ib" min='0' className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("institutionalBilling", e.target.value)} value={this.state.updateUser.merchant_charges ? this.state.updateUser.merchant_charges.institutionalBilling : null} disabled={!this.state.editMode} required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Grocery Billing Charges*</label>
                                                                                    <input type="number" name="gb" min='0' className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("groceryBilling", e.target.value)} value={this.state.updateUser.merchant_charges ? this.state.updateUser.merchant_charges.groceryBilling : null} disabled={!this.state.editMode} required />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {this.state.editMode ?
                                                                            <div className="col-lg-12 mt-2 mb-2 text-center">
                                                                                <button type="button" onClick={this.handleOnUpdateCharges} className="btn btn-primary btn-block" disabled={this.state.isUpdatingCharges}>{this.state.isUpdatingCharges ? "Submmiting" : "Submit"}</button>
                                                                            </div>
                                                                            : null}
                                                                    </section>
                                                                </div>
                                                            </form>
                                                        </div>
                                                        <div id="edit-services" className="tab-pane fade">
                                                            <form action="#" id="" className="step-form-horizontal mt-4">
                                                                <div>
                                                                    <h4 className="form_title">Merchant Services  {!this.state.editMode ? <i class="zmdi zmdi-edit" onClick={() => { this.setState({ editMode: true }) }} style={{ color: "#40189D", fontSize: 20, paddingLeft: 15, marginTop: 10 }}></i> : null}</h4>
                                                                    <section>
                                                                        <div className="row">
                                                                            <div className="col-lg-12 mb-2">
                                                                                <div className="form-group">
                                                                                    <input type="checkbox" className="mr-3" disabled={!this.state.editMode} onClick={(e) => this.handleOnChangeFields("isRentalServiceAllowed", e.target.checked)} defaultChecked={this.state.updateUser.isRentalServiceAllowed} />
                                                                                    <label className="text-label">Rental Billing</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-12 mb-2">
                                                                                <div className="form-group">
                                                                                    <input type="checkbox" className="mr-3" disabled={!this.state.editMode} onClick={(e) => this.handleOnChangeFields("isInstitutionalServiceAllowed", e.target.checked)} defaultChecked={this.state.updateUser.isInstitutionalServiceAllowed} />
                                                                                    <label className="text-label">Institutional Billing</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-12 mb-2">
                                                                                <div className="form-group">
                                                                                    <input type="checkbox" className="mr-3" disabled={!this.state.editMode} onClick={(e) => this.handleOnChangeFields("isGroceryServiceAllowed", e.target.checked)} defaultChecked={this.state.updateUser.isGroceryServiceAllowed} />
                                                                                    <label className="text-label">Grocery Billing</label>
                                                                                </div>
                                                                            </div>
                                                                            {this.state.editMode ?
                                                                                <div className="col-lg-12 mt-2 mb-2 text-center">
                                                                                    <button type="button" onClick={this.handleOnUpdateServices} className="btn btn-primary btn-block" disabled={this.state.isLoading}>{this.state.isLoading ? "Updating" : "Update"}</button>
                                                                                </div>
                                                                                : null}
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <button className="btn btn-primary btn-rounded btn-block mb-2" data-toggle="modal" data-target="#merchantChangePasswordModal">Change Password</button>
                                            <button className="btn btn-primary btn-rounded btn-block mb-2" data-toggle="modal" data-target="#merchantUpdateWalletModal" onClick={() => this.handleOnUpdateWalletType("debitWallet")}>Debit Wallet</button>
                                            <button className="btn btn-primary btn-rounded btn-block mb-2" data-toggle="modal" data-target="#merchantUpdateWalletModal" onClick={() => this.handleOnUpdateWalletType("debitPGWallet")}>Debit PG Wallet</button>
                                            <button className="btn btn-primary btn-rounded btn-block mb-2" data-toggle="modal" data-target="#merchantUpdateWalletModal" onClick={() => this.handleOnUpdateWalletType("creditWallet")}>Credit Wallet</button>
                                            <div className="row">
                                                <WalletCard
                                                    heading="Payment Gateway Wallet"
                                                    amount={numberFormat(this.state.user.pg_balance)}
                                                    cardColor="primary"
                                                    cardCol="col-xl-12"
                                                />
                                                <WalletCard
                                                    heading="Regular Wallet"
                                                    amount={numberFormat(this.state.user.wallet_balance)}
                                                    cardColor="success"
                                                    cardCol="col-xl-12"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isLightBoxOpen && (
                    <Lightbox
                        mainSrc={this.state.selectedImage}
                        onCloseRequest={() => this.setState({ isLightBoxOpen: false })}
                    />
                )}
                {/* Modal */}
                <div className="modal fade" id="merchantChangePasswordModal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">Change Password</div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-lg-12 mb-2">
                                        <div className="form-group">
                                            <label className="text-label"><strong>New Password</strong></label>
                                            <input type="password" name="confirmPassword" onChange={(e) => this.handleOnChangeFields("new_password", e.target.value)} className="form-control" placeholder="Enter New Password" required />
                                        </div>
                                        <div className="form-group">
                                            <label className="text-label"><strong>Confirm New Password</strong></label>
                                            <input type="password" name="confirmPassword" onChange={(e) => this.handleOnChangeFields("confirm_new_password", e.target.value)} className="form-control" placeholder="Confirm Password" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary btn-block" onClick={this.handleOnUpdatePassword} disabled={this.state.isUpdatingPassword}>{this.state.isUpdatingPassword ? "Updating..." : "Update"}</button>
                                    <button type="button" className="d-none" id="merchant-password-model-close-button" data-dismiss="modal"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* merchantUpdateWalletModal */}
                <div className="modal fade" id="merchantUpdateWalletModal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">{this.state.walletUpdateType && this.state.walletUpdateType === 'debitWallet' ? "Debit" : this.state.walletUpdateType === 'debitPGWallet' ? "Debit PG" : this.state.walletUpdateType === 'creditWallet' ? "Credit" : ''} Wallet</div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-lg-12 mb-2">
                                        <div className="form-group">
                                            <label className="text-label"><strong>Amount *</strong></label>
                                            <input type="number" name="amount" onChange={(e) => this.handleOnUpdateWalletField("amount", e.target.value)} value={this.state.updateWallet && this.state.updateWallet.amount ? this.state.updateWallet.amount : ''} className="form-control" placeholder="Enter Amount" required />
                                        </div>
                                        <div className="form-group">
                                            <label className="text-label"><strong>Notes</strong></label>
                                            <textarea name="notes" onChange={(e) => this.handleOnUpdateWalletField("notes", e.target.value)} value={this.state.updateWallet && this.state.updateWallet.notes ? this.state.updateWallet.notes : ''} className="form-control" placeholder="Enter Notes" required ></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary btn-block" onClick={this.handleOnUpdateWallet} disabled={this.state.isUpdatingWallet}>{this.state.isUpdatingWallet ? "Updating..." : "Update"}</button>
                                    <button type="button" className="d-none" id="wallet-update-model-close-button" data-dismiss="modal"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* updateCertificateImage */}
                <div className="modal fade" id="updateCertificateImage">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">Update Certificate</div>
                            <div className="modal-body">
                                <form>
                                    <div className="row">
                                        <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>Certificate</strong></label>
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input type="file" accept="image/x-png,image/jpeg" onChange={(e) => this.handleOnUploadImage("certificate_image", e.target.files, "certificate-file-name")} className="custom-file-input" required />
                                                        <label className="custom-file-label" id="certificate-file-name">Choose file</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type="button" onClick={this.handleUpdateCertificateImage} className="btn btn-primary btn-block" disabled={this.state.isUpdatingCertificate}>{this.state.isUpdatingCertificate ? "Saving..." : "Save"}</button>
                                        <button type="button" className="d-none" id="update-certificate-model-close-button" data-dismiss="modal"></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


                {/* updateAadharCard */}
                <div className="modal fade" id="updateAadharCard">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">Update Aadhaar Card</div>
                            <div className="modal-body">
                                <form>
                                    <div className="row">
                                        <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>Aadhaar Card Front</strong></label>
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input type="file" accept="image/x-png,image/jpeg" onChange={(e) => this.handleOnUploadImage("aadhar_front_image", e.target.files, 'aadhar-front-file-name')} className="custom-file-input" required />
                                                        <label className="custom-file-label" id="aadhar-front-file-name">Choose file</label>
                                                    </div>
                                                </div>
                                                <label className="text-label"><strong>Aadhaar Card Back</strong></label>
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input type="file" accept="image/x-png,image/jpeg" onChange={(e) => this.handleOnUploadImage("aadhar_back_image", e.target.files, 'aadhar-back-file-name')} className="custom-file-input" required />
                                                        <label className="custom-file-label" id="aadhar-back-file-name">Choose file</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type="button" onClick={this.handleUpdateAadhaarCard} className="btn btn-primary btn-block" disabled={this.state.isUpdatingAadhar}>{this.state.isUpdatingAadhar ? "Saving..." : "Save"}</button>
                                        <button type="button" className="d-none" id="update-aadhar-model-close-button" data-dismiss="modal"></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {/* updatePanCard */}
                <div className="modal fade" id="updatePanCard">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">Update Pan Card</div>
                            <div className="modal-body">
                                <form>
                                    <div className="row">
                                        <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>Pan Card</strong></label>
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input type="file" accept="image/x-png,image/jpeg" onChange={(e) => this.handleOnUploadImage("pan_image", e.target.files, 'pan-card-file-name')} className="custom-file-input" required />
                                                        <label className="custom-file-label" id="pan-card-file-name">Choose file</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type="button" onClick={this.handleUpdatePanCard} className="btn btn-primary btn-block" disabled={this.state.isUpdatingPan}>{this.state.isUpdatingPan ? "Saving..." : "Save"}</button>
                                        <button type="button" className="d-none" id="update-pan-model-close-button" data-dismiss="modal"></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {/* updateCanceledCheck */}
                <div className="modal fade" id="updateCanceledCheck">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">Update Cancelled Check</div>
                            <div className="modal-body">
                                <form>
                                    <div className="row">
                                        <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>Cancelled Check</strong></label>
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input type="file" accept="image/x-png,image/jpeg" onChange={(e) => this.handleOnUploadImage("canceled_check", e.target.files, 'cancelled-check-file-name')} className="custom-file-input" required />
                                                        <label className="custom-file-label" id="cancelled-check-file-name">Choose file</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type="button" onClick={this.handleUpdateCanceledCheck} className="btn btn-primary btn-block" disabled={this.state.isUpdatingCancelledCheck}>{this.state.isUpdatingCancelledCheck ? "Saving..." : "Save"}</button>
                                        <button type="button" className="d-none" id="update-cancelled-check-model-close-button" data-dismiss="modal"></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


                {/* updateShopPhotosModal */}
                <div className="modal fade" id="updateShopPhotosModal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">Update Shop Photos</div>
                            <div className="modal-body">
                                <form>
                                    <div className="row">
                                        <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>Shop Outside Picture</strong></label>
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input type="file" accept="image/x-png,image/jpeg" onChange={(e) => this.handleOnUploadImage("organization_outside_image", e.target.files, 'organization-outside-image')} className="custom-file-input" required />
                                                        <label className="custom-file-label" id="organization-outside-image">Choose file</label>
                                                    </div>
                                                </div>
                                                <label className="text-label"><strong>Shop Inside Picture</strong></label>
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input type="file" accept="image/x-png,image/jpeg" onChange={(e) => this.handleOnUploadImage("organization_inside_image", e.target.files, 'organization-inside-image')} className="custom-file-input" required />
                                                        <label className="custom-file-label" id="organization-inside-image">Choose file</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type="button" onClick={this.handleUpdateOrganizationImages} className="btn btn-primary btn-block" disabled={this.state.isUpdatingOrganizationImages}>{this.state.isUpdatingOrganizationImages ? "Saving..." : "Save"}</button>
                                        <button type="button" className="d-none" id="update-organization-model-close-button" data-dismiss="modal"></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Update Logo Modal */}
                <div className="modal fade" id="updateLogoModal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">Update Logo</div>
                            <div className="modal-body">
                                <form>
                                    <div className="row">
                                        <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>Logo of Merchant</strong></label>
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input type="file" accept="image/x-png,image/jpeg" onChange={(e) => this.handleOnUploadImage("organization_logo", e.target.files, 'organization-logo-file-name')} className="custom-file-input" required />
                                                        <label className="custom-file-label" id="organization-logo-file-name">Choose file</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type="button" onClick={this.handleUpdateOrganizationLogo} className="btn btn-primary btn-block" disabled={this.state.isUpdatingOrganizationLogo}>{this.state.isUpdatingOrganizationLogo ? "Saving..." : "Save"}</button>
                                        <button type="button" className="d-none" id="organization-logo-model-close-button" data-dismiss="modal"></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: getUserData(state)
    }
}

const mapDispatchToProps = dispatch => ({
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MerchantProfile);