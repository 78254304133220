import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from "redux";
import { connect } from "react-redux";
import { getUserData } from '../../modules/reducers';

class SideBar extends Component {
    render() {
        return (
            <div>
                {/***********************************
            Sidebar start
        ************************************/}
                < div className="deznav" >
                    <div className="deznav-scroll">
                        <ul className="metismenu" id="menu">
                            <li><a href="/dashboard" className="ai-icon" aria-expanded="false">
                                <i className="flaticon-381-networking" />
                                <span className="nav-text">Dashboard</span>
                            </a>
                            </li>
                            <li><a className="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                                <i className="zmdi zmdi-accounts zmdi-hc-4x" />
                                <span className="nav-text">Merchants</span>
                            </a>
                                <ul aria-expanded="false">
                                    <li><a href="/merchant/list">List of Merchants</a></li>
                                    {/* <li><a href="/merchant/utility/transactions">Utility Transactions</a></li> */}
                                    <li><a href="/merchant/billing/transactions">Billing Transactions</a></li>
                                    <li><a href="/wallet/transactions">Wallet Transactions</a></li>
                                </ul>
                            </li>
                            <li><a className="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                                <i className="zmdi zmdi-accounts zmdi-hc-4x" />
                                <span className="nav-text">Customers</span>
                            </a>
                                <ul aria-expanded="false">
                                    <li><a href="/customer/list">List of Customers</a></li>
                                    <li><a href="/customer/charges">Set Charges</a></li>
                                    {/* <li><a href="/customer/utility/transactions">Utility Transactions</a></li> */}
                                    <li><a href="/customer/billing/transactions">Billing Transactions</a></li>
                                </ul>
                            </li>
                            <li><a href="/tickets/list" className="ai-icon" aria-expanded="false">
                                <i class="zmdi zmdi-ticket-star zmdi-hc-4x"></i>
                                <span className="nav-text">Complaints</span>
                            </a>
                            </li>
                            {/* <li><a href="/admin/info" className="ai-icon" aria-expanded="false">
                                <i class="zmdi zmdi-info zmdi-hc-4x"></i>
                                <span className="nav-text">Admin Info</span>
                            </a>
                            </li> */}
                            <li><a href="/admin/pg/keys" className="ai-icon" aria-expanded="false">
                                <i class="zmdi zmdi-key"></i>
                                <span className="nav-text">PG Settings</span>
                            </a>
                            </li>
                        </ul>
                        <div className="copyright">
                            Copyright © {new Date().getFullYear()} EK RUPAY, All rights Reserved
                            {/* <p><strong>EK RUPAY</strong> ©All Rights Reserved</p> */}
                            {/* <p>by DexignZone</p> */}
                        </div>
                    </div>
                </div >
                {/***********************************
          Sidebar end
      ************************************/}
            </div>
        )
    }
}



const mapStateToProps = state => {
    return {
        user: getUserData(state),
    }
}

const mapDispatchToProps = dispatch => ({
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(SideBar);
