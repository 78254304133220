import React, { Component } from 'react';
import swal from 'sweetalert';
import SideBar from '../../components/global/Sidebar';
import TopBar from '../../components/global/Topbar';
import apicaller from '../../util/ApiCaller';
document.addEventListener("wheel", function (event) {
    if (document.activeElement.type === "number") {
        document.activeElement.blur();
    }
});

class CustomerCharges extends Component {

    constructor() {
        super();
        this.state = {
            CustomerCharges: {},
            isUpdatingCharges: false
        }
    }

    componentDidMount() {
        this.fetchCustomerCharges();
    }

    fetchCustomerCharges = () => {
        apicaller('get', '/admin/get/customer-charges')
            .then(res => {
                if (res.status == 200) {
                    this.setState({
                        CustomerCharges: res.data.data.customerChargesStructure
                    })
                }
                else {
                    swal('Error', 'some internal issue', 'error');
                }
            })
            .catch(err => {
                swal('Error', err.message, 'error');
            })
    }

    handleOnChangeMerchantChargesFields = (userField, value) => {
        let CustomerCharges = this.state.CustomerCharges;
        CustomerCharges[userField] = parseFloat(value);
        this.setState({
            CustomerCharges,
        })
    }

    handleOnUpdateCharges = () => {
        this.setState({
            isUpdatingCharges: true
        })
        let data = {
            "creditCard": this.state.CustomerCharges.creditCard,
            "debitCard": this.state.CustomerCharges.debitCard,
            "netBanking": this.state.CustomerCharges.netBanking,
            "upi": this.state.CustomerCharges.upi,
            "wallet": this.state.CustomerCharges.wallet,
            "amexCard": this.state.CustomerCharges.amexCard
        };
        apicaller('post', `admin/customer-charges/${this.state.CustomerCharges._id}/update`, data)
            .then(res => {
                if (res.status == 200) {
                    if (res.data.data.customerCharges) {
                        swal('Success', 'Charges updated Sucessfully.', 'success');
                        this.setState({
                            editMode: false
                        })
                    }
                    else {
                        swal('Error', 'some internal issue', 'error');
                    }
                }
                else {
                    swal('Error', 'some internal issue', 'error');
                }
                this.setState({
                    isUpdatingCharges: false
                })
            })
            .catch(err => {
                swal('Error', err.message, 'error');
                this.setState({
                    isUpdatingCharges: false
                })
            })
    }

    render() {
        return (
            <div>
                <title>Customer Charges</title>
                <div id="main-wrapper">
                    <TopBar
                        title="Customer"
                    />
                    <SideBar />
                    <div class="content-body">
                        <div class="container-fluid">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-header">
                                        <h4 class="card-title">Charges Structure</h4>
                                    </div>
                                    <div class="card-body">
                                        <form action="#" id="" className="step-form-horizontal mt-4">
                                            <div>
                                                <h4 className="form_title">View / Edit Charges  {!this.state.editMode ? <i class="zmdi zmdi-edit" onClick={() => { this.setState({ editMode: true }) }} style={{ color: "#40189D", fontSize: 20, paddingLeft: 15, marginTop: 10 }}></i> : null}</h4>
                                                <section>
                                                    <div className="row">
                                                        <div className="col-lg-6 mb-2">
                                                            <div className="form-group">
                                                                <label className="text-label">Credit Card Charges*</label>
                                                                <input type="number" name="creditCard" className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("creditCard", e.target.value)} value={this.state.CustomerCharges ? this.state.CustomerCharges.creditCard : null} disabled={!this.state.editMode} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 mb-2">
                                                            <div className="form-group">
                                                                <label className="text-label">Debit Card Charges*</label>
                                                                <input type="number" name="debitCard" className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("debitCard", e.target.value)} value={this.state.CustomerCharges ? this.state.CustomerCharges.debitCard : null} disabled={!this.state.editMode} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 mb-2">
                                                            <div className="form-group">
                                                                <label className="text-label">Net Banking Charges*</label>
                                                                <input type="number" name="netBanking" className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("netBanking", e.target.value)} value={this.state.CustomerCharges ? this.state.CustomerCharges.netBanking : null} disabled={!this.state.editMode} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 mb-2">
                                                            <div className="form-group">
                                                                <label className="text-label">UPI Charges*</label>
                                                                <input type="number" name="upi" className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("upi", e.target.value)} value={this.state.CustomerCharges ? this.state.CustomerCharges.upi : null} disabled={!this.state.editMode} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 mb-2">
                                                            <div className="form-group">
                                                                <label className="text-label">Wallet Charges*</label>
                                                                <input type="number" name="wallet" className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("wallet", e.target.value)} value={this.state.CustomerCharges ? this.state.CustomerCharges.wallet : null} disabled={!this.state.editMode} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 mb-2">
                                                            <div className="form-group">
                                                                <label className="text-label">AMEX Card Charges*</label>
                                                                <input type="number" name="amexCard" className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("amexCard", e.target.value)} value={this.state.CustomerCharges ? this.state.CustomerCharges.amexCard : null} disabled={!this.state.editMode} required />
                                                            </div>
                                                        </div>
                                                        {this.state.editMode ?
                                                            <div className="col-lg-12 mt-2 mb-2 text-center">
                                                                <button type="button" onClick={this.handleOnUpdateCharges} className="btn btn-primary btn-block" disabled={this.state.isUpdatingCharges}>{this.state.isUpdatingCharges ? "Submitting..." : "Submit"}</button>
                                                            </div>
                                                            : null}
                                                    </div>
                                                </section>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CustomerCharges;