import React, { Component } from 'react';
import SideBar from '../../components/global/Sidebar';
import TopBar from '../../components/global/Topbar';
import apicaller from '../../util/ApiCaller';
import _ from 'lodash';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Loader from '../../components/global/Loader';
import swal from 'sweetalert';

class CustomerProfile extends Component {
    constructor() {
        super();
        this.state = {
            user: {},
            updateUser: {},
            isLightBoxOpen: false,
            selectedImage: '',
            isLoading: false,
            isKycApproving: false
        }
    }

    componentDidMount() {
        let userId = this.props.match.params.id;
        this.fetchUserProfileDetails(userId);
    }

    getImage = async (fieldName, fileKey) => {
        await apicaller('get', `get/file?fileKey=${fileKey}`)
            .then(res => {
                if (res.status == 200) {
                    let user = this.state.user;
                    let buf = Buffer.from(res.data);
                    let base64 = buf.toString('base64');
                    let final_image = `data:image/jpeg;base64,${base64}`
                    user[fieldName] = final_image
                    this.setState({ user, updateUser: user })
                }
                else {
                    swal('Error', 'some internal issue', 'error');
                }
            })
    }

    downloadUserImages = async () => {
        if (this.state.user.pan_image) {
            await this.getImage("pan_image", this.state.user.pan_image)
        }
        if (this.state.user.pan_image) {
            await this.getImage("aadhar_front_image", this.state.user.aadhar_front_image)
        }
        if (this.state.user.pan_image) {
            await this.getImage("aadhar_back_image", this.state.user.aadhar_back_image)
        }
    }

    fetchUserProfileDetails = (userId) => {
        this.setState({
            isLoading: true
        })
        apicaller('get', `user/${userId}/profile`)
            .then(res => {
                if (res.status == 200) {
                    if (res.data.data.user) {
                        this.setState({
                            user: res.data.data.user
                        }, () => {
                            this.downloadUserImages();
                        })
                    }
                    else {
                        swal('Error', 'some internal issue', 'error');
                    }
                }
                else {
                    swal('Error', 'some internal issue', 'error');
                }
            })
            .catch(err => {
                swal('Error', err.message, 'error');
            })
        this.setState({
            isLoading: false
        })
    }
    executeScroll = () => this.myRef.scrollIntoView()

    handleOnChangeFields = (userField, value) => {
        let updateUser = this.state.updateUser;
        updateUser[userField] = value
        this.setState({
            updateUser,
        })
    }

    handleOnUploadFiles = (userField, files) => {
        let value;
        for (var i in files) {
            if (i != "length" && i != "item") {
                var FileSize = files[i].size / 1024 / 1024; // in MB
                if (FileSize > 3) {
                    swal('Warning', 'File size exceeds 3 MB', 'warning');
                }
                value = files[i];
            }
        }
        let updateUser = this.state.updateUser;
        updateUser[userField] = value
        this.setState({
            updateUser,
        })
    }

    validateUserFields(input) {
        let output = {};
        output.isValid = true;
        output.message = "";
        if (!input.aadhar_back_image || (input.aadhar_back_image.size / 1024 / 1024) > 3) {
            output.isValid = false;
            output.message = "Aadhar back image should be less than 3MB";
        }
        if (!input.aadhar_back_image || !_.isEmpty(input.aadhar_back_image)) {
            output.isValid = false;
            output.message = "Aadhar back image image is required";
        }
        if (!input.aadhar_front_image || (input.aadhar_front_image.size / 1024 / 1024) > 3) {
            output.isValid = false;
            output.message = "Aadhar front image should be less than 3MB";
        }
        if (!input.aadhar_front_image || !_.isEmpty(input.aadhar_front_image)) {
            output.isValid = false;
            output.message = "Aadhar front image image is required";
        }
        if (!input.aadhar_number || input.aadhar_number == "") {
            output.isValid = false;
            output.message = "Please enter aadhar number";
        }
        if (!input.pan_image || (input.pan_image.size / 1024 / 1024) > 3) {
            output.isValid = false;
            output.message = "Pan card image should be less than 3MB";
        }
        if (!input.pan_image || !_.isEmpty(input.pan_image)) {
            output.isValid = false;
            output.message = "Pan card image is required";
        }
        if (!input.pan_number || input.pan_number == "") {
            output.isValid = false;
            output.message = "Please enter pan number";
        }
        return output;
    }

    handleOnCompleteKycUpdate = () => {
        this.setState({
            isLoading: true
        })
        let updateUser = this.state.updateUser;
        let validation = this.validateUserFields(updateUser);
        if (validation.isValid) {
            let data = new FormData();
            data.append('pan_number', updateUser.pan_number);
            data.append('pan_image', updateUser.pan_image);
            data.append('aadhar_number', updateUser.aadhar_number);
            data.append('aadhar_front_image', updateUser.aadhar_front_image);
            data.append('aadhar_back_image', updateUser.aadhar_back_image);
            data.append('isKycProcessed', true);
            data.append('isKycCompleted', true);
            data.append('phone', this.state.user.phone);
            apicaller('post', 'user/customer/complete-kyc', data)
                .then(res => {
                    if (res.status == 200) {
                        if (res.data.data.user) {
                            this.setState({
                                user: res.data.data.user
                            })
                            document.getElementById('update-model-close-button').click();
                            this.fetchUserProfileDetails(this.props.match.params.id);
                        }
                    }
                })
        }
        else {
            swal('Warning', validation.message, 'warning');
        }
        this.setState({
            isLoading: false
        })
    }

    validateUpdatePassword = (input) => {
        let output = {};
        output.isValid = true;
        output.message = "";
        if (!input.password || input.new_password == "") {
            output.isValid = false;
            output.message = "Please enter new password";
        }
        if (!input.confirm_new_password || input.confirm_new_password == "") {
            output.isValid = false;
            output.message = "Please enter confirm password";
        }
        if (input.password != input.confirm_new_password) {
            output.isValid = false;
            output.message = "Confirm password is incorrect";
        }
        return output;
    }

    handleOnUpdatePassword = () => {
        this.setState({
            isLoading: true
        })
        let data = {};
        data.password = this.state.updateUser.new_password;
        data.confirm_new_password = this.state.updateUser.confirm_new_password;
        let validation = this.validateUpdatePassword(data);
        if (validation.isValid) {
            apicaller('post', `user/${this.state.user._id}/profile/update`, data)
                .then(res => {
                    if (res.status == 200) {
                        if (res.data.data.user) {
                            swal('Success', 'password updated successfully', 'success');
                        }
                        else {
                            swal('Error', res.data.data.message, 'error');
                        }
                        document.getElementById('customer-password-model-close-button').click();
                    }
                    else {
                        swal('Error', 'some internal issue', 'error');
                    }
                })
                .catch(err => {
                    swal('Error', err.message, 'error');
                })
        }
        else {
            swal('Warning', validation.message, 'warning');
        }
        this.setState({
            isLoading: false
        })
    }

    handleOnUpdateProfile = () => {
        this.setState({
            isLoading: true
        })
        let data = {};
        data.first_name = this.state.updateUser.first_name || this.state.user.first_name;
        data.last_name = this.state.updateUser.last_name || this.state.user.last_name;
        apicaller('post', `user/${this.state.user._id}/profile/update`, data)
            .then(res => {
                if (res.status == 200) {
                    document.getElementById('update-profile-model-close-button').click();
                    swal('Success', "user updated successfully", 'success');
                }
                else {
                    swal('Error', 'some internal issue', 'error');
                }
                this.setState({
                    isLoading: false
                })
            })
            .catch(err => {
                swal('Error', err.message, 'error');
                this.setState({
                    isLoading: false
                })
            })
        this.setState({
            isLoading: false
        })
    }

    handleOnUploadImage = (userField, files) => {
        let value;
        for (var i in files) {
            if (i != "length" && i != "item") {
                var FileSize = files[i].size / 1024 / 1024; // in MB
                if (FileSize > 3) {
                    swal('Warning', 'File size exceeds 3 MB', 'warning');
                }
                value = files[i];
            }
        }
        this.setState({
            [userField]: value,
        })
    }

    handleApproveKyc = () => {
        this.setState({
            isKycApproving: true
        })
        let data = {};
        data.isKycCompleted = true;
        apicaller('post', `user/${this.state.user._id}/profile/update`, data)
            .then(res => {
                if (res.status == 200) {
                    swal('Success', "KYC Approved", 'success');
                    this.props.history.push(`/customer/profile/${this.props.match.params.id}`)
                }
                else {
                    swal('Error', 'some internal issue', 'error');
                }
                this.setState({
                    isKycApproving: false
                })
            })
            .catch(err => {
                swal('Error', err.message, 'error');
                this.setState({
                    isKycApproving: false
                })
            })
        this.setState({
            isKycApproving: false
        })
    }

    render() {
        return (
            <div>
                {this.state.isLoading ?
                    <Loader />
                    :
                    <div>
                        <title>Profile</title>
                        <div id="main-wrapper">
                            <TopBar
                                title="Profile"
                            />
                            <SideBar />
                            <div class="content-body">
                                <div class="container-fluid">
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="card profile-card">
                                                <div className="card-header flex-wrap border-0 pb-0">
                                                    <h3 className="fs-24 text-black font-w600 mr-auto mb-2 pr-3">Profile Info<br />
                                                        {this.state.user.isKycCompleted ?
                                                            <span className="text-success" style={{ fontSize: 15 }}><i class="zmdi zmdi-star-circle"></i> KYC Completed</span>
                                                            :
                                                            this.state.user.isKycProcessed ?
                                                                <span className="text-warning" style={{ fontSize: 15 }}> KYC under review</span>
                                                                :
                                                                <span className="text-danger" style={{ fontSize: 15 }}> Non KYC User</span>
                                                        }</h3>
                                                    {this.state.user.isKycProcessed || this.state.user.isKycCompleted ? null :
                                                        <button className="btn btn-primary btn-rounded mr-3 mb-2" data-toggle="modal" data-target="#updateKYCModal">Complete KYC</button>
                                                    }
                                                    {this.state.user.isKycProcessed && !this.state.user.isKycCompleted ?
                                                        <button className="btn btn-primary btn-rounded mr-3 mb-2" onClick={this.handleApproveKyc} disabled={this.state.isKycApproving}>{this.state.isKycApproving ? "Approving" : "Approve"}</button>
                                                        : null}
                                                    <button className="btn btn-primary btn-rounded mr-3 mb-2" data-toggle="modal" data-target="#editProfileModal">Edit Profile</button>
                                                    <button className="btn btn-primary btn-rounded mb-2" data-toggle="modal" data-target="#changeCustomerPasswordModal">Change Password</button>
                                                </div>
                                                <div className="card-body">
                                                    <div className="mb-3">
                                                        <div className="row">
                                                            <div className="col-xl-6 col-sm-6 mb-2">
                                                                <h5 className="f-w-500">First Name</h5>
                                                                <span>{this.state.user.first_name || ""}</span>
                                                            </div>
                                                            <div className="col-xl-6 col-sm-6 mb-2">
                                                                <h5 className="f-w-500">Last Name</h5>
                                                                <span>{this.state.user.last_name || ""}</span>
                                                            </div>
                                                            <div className="col-xl-6 col-sm-6 mb-2">
                                                                <h5 className="f-w-500">Email</h5>
                                                                <span>{this.state.user.email || ""}</span>
                                                            </div>
                                                            <div className="col-xl-6 col-sm-6 mb-2">
                                                                <h5 className="f-w-500">Phone Number</h5>
                                                                <span>{this.state.user.phone || ""}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="card">
                                                <div className="card-header flex-wrap border-0 pb-0">
                                                    <h3 className="fs-24 text-black font-w600 mr-auto mb-2 pr-3">KYC Details</h3>
                                                    <button className="btn btn-primary btn-rounded mr-3 mb-2" data-toggle="modal" data-target="#updateKYCModal">Update KYC</button>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                            <h5 className="f-w-500">PAN Card Number</h5>
                                                            <span>{this.state.user.pan_number}</span>
                                                        </div>
                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                            <h5 className="f-w-500">PAN Card</h5>
                                                            <img src={this.state.user.pan_image} onClick={() => this.setState({ isLightBoxOpen: true, selectedImage: this.state.user.pan_image })} alt="" className="mt-2 mb-2 w-50" />
                                                        </div>
                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                            <h5 className="f-w-500">Aadhaar Card Number</h5>
                                                            <span>{this.state.user.aadhar_number}</span>
                                                        </div>
                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                            <h5 className="f-w-500">Aadhaar Card Front Image</h5>
                                                            <img src={this.state.user.aadhar_front_image} onClick={() => this.setState({ isLightBoxOpen: true, selectedImage: this.state.user.aadhar_front_image })} alt="" className="mt-2 mb-2 w-50" />
                                                        </div>
                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                            <h5 className="f-w-500">Aadhaar Card Back Image</h5>
                                                            <img src={this.state.user.aadhar_back_image} onClick={() => this.setState({ isLightBoxOpen: true, selectedImage: this.state.user.aadhar_back_image })} alt="" className="mt-2 mb-2 w-50" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Change Password Modal */}
                        <div className="modal fade" id="changeCustomerPasswordModal">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">Change Password</div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-lg-12 mb-2">
                                                <div className="form-group">
                                                    <label className="text-label"><strong>New Password</strong></label>
                                                    <input type="password" name="new_password" className="form-control" onChange={(e) => this.handleOnChangeFields("new_password", e.target.value)} placeholder="Enter New Password" required />
                                                </div>
                                                <div className="form-group">
                                                    <label className="text-label"><strong>Confirm New Password</strong></label>
                                                    <input type="password" name="confirm_new_password" className="form-control" onChange={(e) => this.handleOnChangeFields("confirm_new_password", e.target.value)} placeholder="Confirm New Password" required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" className="btn btn-primary btn-block" onClick={this.handleOnUpdatePassword} disabled={this.state.isLoading}>{this.state.isLoading ? "Saving" : "Save"}</button>
                                            <button type="button" className="d-none" id="customer-password-model-close-button" data-dismiss="modal"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.isLightBoxOpen && (
                            <Lightbox
                                mainSrc={this.state.selectedImage}
                                onCloseRequest={() => this.setState({ isLightBoxOpen: false })}
                            />
                        )}

                        {/* updateKYCModal */}
                        <div className="modal fade" id="updateKYCModal">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">Update KYC</div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-lg-12 mb-2">
                                                <div className="form-group">
                                                    <label className="text-label"><strong>Pan Card Number</strong></label>
                                                    <input type="text" name="pan_car_num" className="form-control" onChange={(e) => this.handleOnChangeFields("pan_number", e.target.value)} placeholder="Enter Pan Number" required />
                                                    <label className="text-label"><strong>Pan Card</strong></label>
                                                    <div className="input-group">
                                                        <div className="custom-file">
                                                            <input type="file" accept="image/x-png,image/jpeg" onChange={(e) => this.handleOnUploadFiles("pan_image", e.target.files)} className="custom-file-input" required />
                                                            <label className="custom-file-label">Choose file</label>
                                                        </div>
                                                    </div>
                                                    <label className="text-label"><strong>Aadhaar Card Number</strong></label>
                                                    <input type="text" name="aadhar_card_num" className="form-control" onChange={(e) => this.handleOnChangeFields("aadhar_number", e.target.value)} placeholder="Enter Aadhar Number" required />
                                                    <label className="text-label"><strong>Aadhaar Card Front</strong></label>
                                                    <div className="input-group">
                                                        <div className="custom-file">
                                                            <input type="file" accept="image/x-png,image/jpeg" onChange={(e) => this.handleOnUploadFiles("aadhar_front_image", e.target.files)} className="custom-file-input" required />
                                                            <label className="custom-file-label">Choose file</label>
                                                        </div>
                                                    </div>
                                                    <label className="text-label"><strong>Aadhaar Card Back</strong></label>
                                                    <div className="input-group">
                                                        <div className="custom-file">
                                                            <input type="file" accept="image/x-png,image/jpeg" onChange={(e) => this.handleOnUploadFiles("aadhar_back_image", e.target.files)} className="custom-file-input" required />
                                                            <label className="custom-file-label">Choose file</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" className="btn btn-primary btn-block" onClick={this.handleOnCompleteKycUpdate}>Save</button>
                                            <button type="button" className="d-none" id="update-model-close-button" data-dismiss="modal"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Edit Profile Modal */}
                        <div className="modal fade" id="editProfileModal">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">Edit Profile</div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-lg-12 mb-2">
                                                <div className="form-group">
                                                    <label className="text-label"><strong>First Name</strong></label>
                                                    <input type="text" className="form-control" placeholder="Enter First Name" onChange={(e) => this.handleOnChangeFields("first_name", e.target.value)} value={this.state.updateUser.first_name} required />
                                                </div>
                                                <div className="form-group">
                                                    <label className="text-label"><strong>Last Name</strong></label>
                                                    <input type="text" className="form-control" placeholder="Enter Last Name" onChange={(e) => this.handleOnChangeFields("last_name", e.target.value)} value={this.state.updateUser.last_name} required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" className="btn btn-primary btn-block" onClick={this.handleOnUpdateProfile} disabled={this.state.isLoading}>{this.state.isLoading ? "Saving" : "Save"}</button>
                                            <button type="button" className="d-none" id="update-profile-model-close-button" data-dismiss="modal"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>
        )
    }
}

export default CustomerProfile;