import React, { Component } from 'react';
import swal from 'sweetalert';
import SideBar from '../../components/global/Sidebar';
import TopBar from '../../components/global/Topbar';
import apicaller from '../../util/ApiCaller';

class Tickets extends Component {

    constructor() {
        super();
        this.state = {
            tickets: [],
            selectedTicket: "",
            mail: {},
            sms: {},
            isSendingSms: false,
            isSendingMail: false,
        }
    }

    componentDidMount() {
        this.fetchAllTickets();
    }

    fetchAllTickets = () => {
        apicaller('get', 'admin/get/tickets')
            .then(res => {
                if (res.status == 200) {
                    this.setState({
                        tickets: res.data.data.tickets
                    })
                }
                else {
                    swal('Error', 'some internal issue', 'error');
                }
            })
            .catch(err => {
                swal('Error', err.message, 'error');
            })
    }

    handleOnMarkResolved = (ticketId) => {
        let data = {};
        data.isActive = false;
        apicaller('post', `admin/ticket/${ticketId}/update`, data)
            .then(res => {
                if (res.status == 200) {
                    swal('Success', 'Marked as resolved.', 'success');
                    this.fetchAllTickets();
                }
                else {
                    swal('Error', 'some internal issue', 'error');
                }
            })
            .catch(err => {
                swal('Error', err.message, 'error');
            })
    }

    handleOnReplyViaMail = (ticketId) => {
        this.setState({
            selectedTicket: ticketId
        })
        document.getElementById('mailModalTrigger').click();
    }

    handleOnReplyViaSms = (ticketId) => {
        this.setState({
            selectedTicket: ticketId
        })
        document.getElementById('smsModalTrigger').click();
    }

    handleOnChangeMailFields = (mailField, value) => {
        let mail = this.state.mail;
        mail[mailField] = value;
        this.setState({
            mail
        })
    }

    handleOnChangeSmsFields = (smsField, value) => {
        let sms = this.state.sms;
        sms[smsField] = value;
        this.setState({
            sms
        })
    }

    handleOnSubmitMail = () => {
        let subject = this.state.mail.subject;
        let message = this.state.mail.message;
        if (subject && subject != "" && message && message != "") {
            this.setState({
                isSendingMail: true
            })
            let data = {};
            data.subject = subject;
            data.message = message;
            console.log(data);
            apicaller('post', `admin/ticket/${this.state.selectedTicket}/reply-via-mail`, data)
                .then(res => {
                    if (res.status == 200) {
                        swal('Success', 'Mail Sent Sucessfully.', 'success');
                        document.getElementById('mail-model-close-button').click();
                        this.fetchAllTickets();
                    }
                    else {
                        swal('Error', 'some internal issue', 'error');
                    }
                    this.setState({
                        isSendingMail: false
                    })
                })
                .catch(err => {
                    swal('Error', err.message, 'error');
                    this.setState({
                        isSendingMail: false
                    })
                })
        }
        else {
            swal('Warning', 'Subject and Message are mandotary fields', 'warning')
        }
    }

    handleOnSubmitSms = () => {
        let message = this.state.sms.message;
        if (message && message != "") {
            this.setState({
                isSendingSms: true
            })
            let data = {};
            data.message = message;
            apicaller('post', `admin/ticket/${this.state.selectedTicket}/reply-via-sms`, data)
                .then(res => {
                    if (res.status == 200) {
                        swal('Success', 'SMS Sent Sucessfully.', 'success');
                        document.getElementById('sms-model-close-button').click();
                        this.fetchAllTickets();
                    }
                    else {
                        swal('Error', 'some internal issue', 'error');
                    }
                    this.setState({
                        isSendingSms: false
                    })
                })
                .catch(err => {
                    swal('Error', err.message, 'error');
                    this.setState({
                        isSendingSms: false
                    })
                })
        }
        else {
            swal('Warning', 'Message is a mandotary field', 'warning')
        }
    }

    render() {
        return (
            <div>
                <title>Complaints</title>
                <div id="main-wrapper">
                    <TopBar
                        title="Complaints"
                    />
                    <SideBar />
                    <div class="content-body">
                        <div class="container-fluid">
                            <div class="col-12">
                                <div class="card">

                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <table id="example4" class="display min-w850">
                                                <thead>
                                                    <tr>
                                                        <th>Actions</th>
                                                        <th>User Id</th>
                                                        <th>Name</th>
                                                        <th>Phone</th>
                                                        <th>Email</th>
                                                        <th>Issue</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr></tr>
                                                    {this.state.tickets && this.state.tickets.length > 0 ?
                                                        this.state.tickets.map(item => (
                                                            <tr>
                                                                <td>
                                                                    <div class="dropdown ml-auto text-center">
                                                                        <div class="btn-link" data-toggle="dropdown">
                                                                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
                                                                        </div>
                                                                        <div class="dropdown-menu dropdown-menu-right">
                                                                            <a class="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => this.handleOnReplyViaSms(item._id)}>Reply via SMS</a>
                                                                            <a class="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => this.handleOnReplyViaMail(item._id)}>Reply via Mail</a>
                                                                            {item.isActive ? <a class="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => this.handleOnMarkResolved(item._id)}>Mark as resolved</a> : null}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>{item.userId.userId}</td>
                                                                <td>{item.userId.first_name || ""} {item.userId.last_name || ""}</td>
                                                                <td>{item.userId.phone}</td>
                                                                <td>{item.userId.email}</td>
                                                                <td>{item.issue}</td>
                                                                <td>{item.isActive ? (<td><span class="badge light badge-danger">Pending</span></td>)
                                                                    :
                                                                    (<td><span class="badge light badge-success">Resolved</span></td>)
                                                                }</td>
                                                            </tr>
                                                        ))
                                                        :
                                                        (<tr>No data available in table</tr>)}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <button type="button" className="d-none" id="smsModalTrigger" data-toggle="modal" data-target="#smsModal"></button>
                <button type="button" className="d-none" id="mailModalTrigger" data-toggle="modal" data-target="#mailModal"></button>

                <div className="modal fade" id="smsModal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-lg-12 mb-2">
                                        <div className="form-group">
                                            <label className="text-label"><strong>Message*</strong></label>
                                            <textarea className="form-control" rows="5" onChange={(e) => this.handleOnChangeSmsFields("message", e.target.value)} placeholder="EnterMessage"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button type="submit" onClick={this.handleOnSubmitSms} className="btn btn-primary btn-block" disabled={this.state.isSendingSms}>{this.state.isSendingSms ? "Submitting..." : "Submit"}</button>
                                    <button type="button" className="d-none" id="sms-model-close-button" data-dismiss="modal"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="mailModal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <h1>Send mail</h1>
                                <div className="row">
                                    <div className="col-lg-12 mb-2">
                                        <div className="form-group">
                                            <label className="text-label"><strong>Subject*</strong></label>
                                            <input type="text" name="phone" className="form-control" onChange={(e) => this.handleOnChangeMailFields("subject", e.target.value)} placeholder="Enter Subject" required />
                                            <label className="text-label"><strong>Message*</strong></label>
                                            <textarea className="form-control" rows="5" onChange={(e) => this.handleOnChangeMailFields("message", e.target.value)} placeholder="EnterMessage"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button type="submit" onClick={this.handleOnSubmitMail} className="btn btn-primary btn-block" disabled={this.state.isSendingMail}>{this.state.isSendingMail ? "Submitting..." : "Submit"}</button>
                                    <button type="button" className="d-none" id="mail-model-close-button" data-dismiss="modal"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Tickets;