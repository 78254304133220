import React, { Component } from 'react';
import SideBar from '../../components/global/Sidebar';
import TopBar from '../../components/global/Topbar';
import DataCard from '../../components/customer/dashboard/DataCard';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "redux";

class AdminInfo extends Component {
    render() {
        return (
            <div>
                <title>Admin Info</title>
                <div id="main-wrapper">
                    <TopBar
                        title="Admin Info"
                    />
                    <SideBar />
                    <div class="content-body">
                        <div class="container-fluid">
                            <h1>Changes To Be Done </h1>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => ({
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AdminInfo);
