import React, { Component } from 'react';
import swal from 'sweetalert';
import SideBar from '../../components/global/Sidebar';
import TopBar from '../../components/global/Topbar';
import apicaller from '../../util/ApiCaller';
document.addEventListener("wheel", function (event) {
    if (document.activeElement.type === "number") {
        document.activeElement.blur();
    }
});

class Pgkeys extends Component {

    constructor() {
        super();
        this.state = {
            adminData: {},
            isUpdatingPgKeys: false,
            razorpayDmrActive: false,
            easyBuzzDmrActive: false,
            accountsList: [],
            dmrPgAccountId: '',
            isDeletingAccount: false,
            newAccount: {},
            isCreatingNewAccount: false,
            editModeEb:false,
            editModeActivePG:false
        }
    }

    componentDidMount() {
        this.fetchAdminData();
        this.fetchAllAccounts();
    }

    fetchAdminData = () => {
        apicaller('get', `admin/admin-data/get`)
            .then(res => {
                if (res.status == 200) {
                    this.setState({
                        adminData: res.data.data.admin_data[0],
                        dmrPgAccountId: res.data.data.admin_data[0].dmrPgAccountId._id,
                        razorpayDmrActive: res.data.data.admin_data[0].razorpayDmrActive,
                        easyBuzzDmrActive: res.data.data.admin_data[0].easyBuzzDmrActive,
                    });
                }
                else {
                    swal('Error', 'some internal issue', 'error');
                }
            })
            .catch(err => {
                swal('Error', err.message, 'error');
            })
    }

    fetchAllAccounts = () => {
        apicaller('post', `dmrPgData/list`)
            .then(res => {
                if (res.status == 200) {
                    this.setState({
                        accountsList: res.data.data.dmrPgDatas
                    })
                }
                else {
                    swal('Error', 'some internal issue', 'error');
                }
            })
            .catch(err => {
                swal('Error', err.message, 'error');
            })
    }
    handleOnChangeMerchantChargesFields = (userField, value) => {
        let adminData = this.state.adminData;
        adminData[userField] = value;
        this.setState({
            adminData,
        })
    }

    validatePgKeys = (input) => {
        let output = {};
        output.isValid = true;
        output.message = '';
        // TODO BHANU : REMOVE COMMENTED CODE BHANU
        // if (!input.dmrAccountNumber || input.dmrAccountNumber === "") {
        //     output.isValid = false;
        //     output.message = 'Razorpay DMR account number is required';
        //     return output;
        // }
        // if (!input.dmrKeyid || input.dmrKeyid === "") {
        //     output.isValid = false;
        //     output.message = 'Razorpay DMR Key id is required';
        //     return output;
        // }
        // if (!input.dmrKeySecret || input.dmrKeySecret === "") {
        //     output.isValid = false;
        //     output.message = 'Razorpay DMR Key secret is required';
        //     return output;
        // }
        // if (!input.easyBuzzDmrKey || input.easyBuzzDmrKey === "") {
        //     output.isValid = false;
        //     output.message = 'Eazybuss DMR Key is required';
        //     return output;
        // }
        // if (!input.easyBuzzDmrSalt || input.easyBuzzDmrSalt === "") {
        //     output.isValid = false;
        //     output.message = 'Eazybuss DMR Key secret is required';
        //     return output;
        // }
        if(this.state.editMode){
        if (!input.gpKeyid || input.gpKeyid === "") {
            output.isValid = false;
            output.message = 'Grocery payment key id is required';
            return output;
        }
        if (!input.gpKeySecret || input.gpKeySecret === "") {
            output.isValid = false;
            output.message = 'Grocery payment key secret is required';
            return output;
        }
        if (!input.ipKeyid || input.ipKeyid === "") {
            output.isValid = false;
            output.message = 'Institutional payment key id is required';
            return output;
        }
        if (!input.ipKeySecret || input.ipKeySecret === "") {
            output.isValid = false;
            output.message = 'Institutional payment key secret is required';
            return output;
        }
        if (!input.rpKeyid || input.rpKeyid === "") {
            output.isValid = false;
            output.message = 'Rental payment key id is required';
            return output;
        }
        if (!input.rpKeySecret || input.rpKeySecret === "") {
            output.isValid = false;
            output.message = 'Rental payment key secret is required';
            return output;
        }
    }
    else if(this.state.editModeEb){
        if (!input.ebGpKeyid || input.ebGpKeyid === "") {
            output.isValid = false;
            output.message = 'Grocery payment key id is required';
            return output;
        }
        if (!input.ebGpKeySecret || input.ebGpKeySecret === "") {
            output.isValid = false;
            output.message = 'Grocery payment key secret is required';
            return output;
        }
        if (!input.ebIpKeyid || input.ebIpKeyid === "") {
            output.isValid = false;
            output.message = 'Institutional payment key id is required';
            return output;
        }
        if (!input.ebIpKeySecret || input.ebIpKeySecret === "") {
            output.isValid = false;
            output.message = 'Institutional payment key secret is required';
            return output;
        }
        if (!input.ebRpKeyid || input.ebRpKeyid === "") {
            output.isValid = false;
            output.message = 'Rental payment key id is required';
            return output;
        }
        if (!input.ebRpKeySecret || input.ebRpKeySecret === "") {
            output.isValid = false;
            output.message = 'Rental payment key secret is required';
            return output;
        } 
    }
        return output;
    }

    handleOnUpdateCharges = () => {
        let data = {
            "razorpayDmrActive": this.state.razorpayDmrActive,
            "easyBuzzDmrActive": this.state.easyBuzzDmrActive,
            "dmrPgAccountId": this.state.dmrPgAccountId,
            // "dmrAccountNumber": this.state.adminData.dmrAccountNumber,
            // "dmrKeyid": this.state.adminData.dmrKeyid,
            // "dmrKeySecret": this.state.adminData.dmrKeySecret,
            // "easyBuzzDmrKey": this.state.adminData.easyBuzzDmrKey,
            // "easyBuzzDmrSalt": this.state.adminData.easyBuzzDmrSalt,
            "gpKeyid": this.state.adminData.gpKeyid,
            "gpKeySecret": this.state.adminData.gpKeySecret,
            "ipKeyid": this.state.adminData.ipKeyid,
            "ipKeySecret": this.state.adminData.ipKeySecret,
            "rpKeyid": this.state.adminData.rpKeyid,
            "rpKeySecret": this.state.adminData.rpKeySecret,
            "ebGpKeyid": this.state.adminData.ebGpKeyid,
            "ebGpKeySecret": this.state.adminData.ebGpKeySecret,
            "ebIpKeyid": this.state.adminData.ebIpKeyid,
            "ebIpKeySecret": this.state.adminData.ebIpKeySecret,
            "ebRpKeyid": this.state.adminData.ebRpKeyid,
            "ebRpKeySecret": this.state.adminData.ebRpKeySecret,
            "razorpayPgActive": this.state.adminData.razorpayPgActive,
            "easyBuzzPgActive": this.state.adminData.easyBuzzPgActive,
        };
        console.log("bhanu",data);
        let validation = this.validatePgKeys(data);
        if (validation.isValid) {
            this.setState({
                isUpdatingPgKeys: true
            })
            apicaller('post', `admin/admin-data/${this.state.adminData._id}/update`, data)
                .then(res => {
                    if (res.status == 200) {
                        if (res.data.data.admin_data) {
                            this.fetchAdminData();
                            swal('Success', 'Keys updated Sucessfully.', 'success');
                            this.setState({
                                editMode: false,
                                editModeEb: false,
                                editModeActivePG:false
                            })
                        }
                        else {
                            swal('Error', 'some internal issue', 'error');
                        }
                    }
                    else {
                        swal('Error', 'some internal issue', 'error');
                    }
                    this.setState({
                        isUpdatingPgKeys: false
                    })
                })
                .catch(err => {
                    swal('Error', err.message, 'error');
                    this.setState({
                        isUpdatingPgKeys: false
                    })
                })
        }
        else {
            swal('Warning', validation.message, 'warning');
        }
    }

    handleOnChangeDmrPortal = (portalName, next) => {
        if (portalName === 'razorpay') {
            this.setState({
                razorpayDmrActive: true,
                easyBuzzDmrActive: false
            }, () => {
                return next(true);
            })
        }
        else {
            this.setState({
                razorpayDmrActive: false,
                easyBuzzDmrActive: true
            }, () => {
                return next(true);
            })
        }
    }

    handleOnSelectAccount = (account) => {
        // TODO Bhanu

        //* uncomment this *

        this.handleOnChangeDmrPortal(account.pgName, (status) => {
            if (status) {
                this.setState({
                    dmrPgAccountId: account._id,
                }, () => { this.handleOnUpdateCharges() })
            }
        });

        //* remove this *
        // this.setState({
        //     dmrPgAccountId: account._id,
        // }, () => { this.handleOnUpdateCharges() })
    }

    handleDeleteAccount = (accountId) => {
        this.setState({
            isDeletingAccount: true
        })
        let data = {
            "dmrPgData": {
                "isDeleted": true
            }
        }
        apicaller('post', `dmrPgData/${accountId}/update`, data)
            .then(res => {
                if (res.status == 200) {
                    this.fetchAllAccounts();
                }
                else {
                    swal('Error', 'some internal issue', 'error');
                }
                this.setState({
                    isDeletingAccount: false
                })
            })
            .catch(err => {
                this.setState({
                    isDeletingAccount: false
                })
                swal('Error', err.message, 'error');
            })
    }

    handleOnChangeNewAccountFields = (field, value) => {
        let newAccount = this.state.newAccount;
        newAccount[field] = value;
        this.setState({
            newAccount
        })
    }
    validateNewAccount = (input) => {
        let output = {};
        output.isValid = true;
        output.message = '';
        if (!input.accountName || input.accountName === "") {
            output.isValid = false;
            output.message = 'Account Name is required';
            return output;
        }
        if (!input.dmrKeyid || input.dmrKeyid === "") {
            output.isValid = false;
            output.message = 'Key id is required';
            return output;
        }
        if (!input.dmrKeySecret || input.dmrKeySecret === "") {
            output.isValid = false;
            output.message = 'Key secret is required';
            return output;
        }
        return output;
    }

    handleOnCreateNewAccount = () => {
        let data = this.state.newAccount;
        let validation = this.validateNewAccount(data);
        if (validation.isValid) {
            this.setState({
                isCreatingNewAccount: true
            })
            apicaller('post', `dmrPgData/new`, {
                dmrPgData: data
            })
                .then(res => {
                    if (res.status == 200) {
                        this.fetchAllAccounts();
                        document.getElementById('addAccountModal').click();
                    }
                    else {
                        swal('Error', 'some internal issue', 'error');
                    }
                    this.setState({
                        isCreatingNewAccount: false
                    })
                })
                .catch(err => {
                    swal('Error', err.message, 'error');
                    this.setState({
                        isCreatingNewAccount: false
                    })
                })
        }
        else {
            swal('Warning', validation.message, 'warning');
        }
    }

    render() {
        let adminData = this.state.adminData;
        return (
            <div>
                <title>Keys</title>
                <div id="main-wrapper">
                    <TopBar
                        title="PG Keys"
                    />
                    <SideBar />
                    <div className="content-body">
                        <div className="container-fluid">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                            <div>
                                                <h4 className="form_title">Manage PG and DMR Keys </h4>
                                                <section>
                                                    <div>
                                                    <h4 className="form_title mt-4 mb-3">Active PG
                                                    {!this.state.editModeActivePG ? <i className="zmdi zmdi-edit" onClick={() => { this.setState({ editModeActivePG: true }) }} style={{ color: "#40189D", fontSize: 20, paddingLeft: 15, marginTop: 10 }}></i> : null}
                                                        </h4>
                                                        <div className='d-flex flex-column'>
                                                        <div>
                                                        <input type="radio" checked={this.state.adminData.razorpayPgActive} name="activePG" onClick={()=>{
                                                            let adminData = this.state.adminData;
                                                            adminData.razorpayPgActive = true;
                                                            adminData.easyBuzzPgActive = false;
                                                            this.setState({
                                                                adminData
                                                            })
                                                        }} disabled={!this.state.editModeActivePG}/>
                                                        <label className={this.state.adminData.razorpayPgActive?'text-primary font-weight-bold':''}
                                                        onClick={()=>{
                                                            let adminData = this.state.adminData;
                                                            adminData.razorpayPgActive = true;
                                                            adminData.easyBuzzPgActive = false;
                                                            this.setState({
                                                                adminData
                                                            })
                                                        }}
                                                        >&nbsp;&nbsp;Razorpay</label>
                                                        </div>
                                                        <div>
                                                        <input type="radio" checked={this.state.adminData.easyBuzzPgActive} name="activePG" onClick={()=>{
                                                            let adminData = this.state.adminData;
                                                            adminData.razorpayPgActive = false;
                                                            adminData.easyBuzzPgActive = true;
                                                            this.setState({
                                                                adminData
                                                            })
                                                        }} disabled={!this.state.editModeActivePG}/>
                                                        <label className={this.state.adminData.easyBuzzPgActive?'text-primary font-weight-bold':''}
                                                        onClick={()=>{
                                                            let adminData = this.state.adminData;
                                                            adminData.razorpayPgActive = false;
                                                            adminData.easyBuzzPgActive = true;
                                                            this.setState({
                                                                adminData
                                                            })
                                                        }}
                                                        >&nbsp;&nbsp;EasyBuzz</label>
                                                        </div>
                                                        </div>
                                                        {this.state.editModeActivePG ?
                                                            <div className="col-lg-12 mt-2 mb-2 text-center">
                                                                <button type="button" onClick={this.handleOnUpdateCharges} className="btn btn-primary btn-block" disabled={this.state.isUpdatingPgKeys}>{this.state.isUpdatingPgKeys ? "Updating..." : "Update"}</button>
                                                            </div>
                                                            : null}
                                                    </div>
                                                    <div>
                                                        <h4 className="form_title mt-4 mb-3">DMR
                                                        <i className="zmdi zmdi-edit" data-toggle="modal" data-target="#manageAccounts" style={{ color: "#40189D", fontSize: 20, paddingLeft: 15, marginTop: 10 }}></i>
                                                        {/* TODO BHANU : CONFIRM AND REMOVE */}
                                                         {/* <a className="text-light p-2 rounded ml-3 bg-primary" style={{ cursor: 'pointer' }} data-toggle="modal" data-target="#manageAccounts">Manage Accounts</a> */}
                                                        </h4>
                                                        <div className="row">
                                                            {adminData && adminData.dmrPgAccountId && adminData.dmrPgAccountId.dmrAccountNumber ?
                                                                <div className="col-lg-6 mb-2">
                                                                    <div className="form-group">
                                                                        <label className="text-label">Account Number*</label>
                                                                        <input type="text" name="dmrAccountNumber" className="form-control" value={adminData && adminData.dmrPgAccountId && adminData.dmrPgAccountId.dmrAccountNumber ? adminData.dmrPgAccountId.dmrAccountNumber : null} disabled={true} required />
                                                                    </div>
                                                                </div>
                                                                :
                                                                null}
                                                            <div className="col-lg-6 mb-2">
                                                                <div className="form-group">
                                                                    <label className="text-label">Key*</label>
                                                                    <input type="text" name="dmrKeyid" className="form-control" value={adminData && adminData.dmrPgAccountId && adminData.dmrPgAccountId.dmrKeyid ? adminData.dmrPgAccountId.dmrKeyid : null} disabled={true} required />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <div className="form-group">
                                                                    <label className="text-label">Secret*</label>
                                                                    <input type="text" name="dmrKeySecret" className="form-control" value={adminData && adminData.dmrPgAccountId && adminData.dmrPgAccountId.dmrKeySecret ? adminData.dmrPgAccountId.dmrKeySecret : null} disabled={true} required />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <div className="form-group">
                                                                    <label className="text-label">Account Name*</label>
                                                                    <input type="text" name="" className="form-control" value={adminData && adminData.dmrPgAccountId && adminData.dmrPgAccountId.accountName ? adminData.dmrPgAccountId.accountName : null} disabled={true} required />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <div className="form-group">
                                                                    <label className="text-label">Pg Name*</label>
                                                                    <input type="text" name="" className="form-control" value={adminData && adminData.dmrPgAccountId && adminData.dmrPgAccountId.pgName ? adminData.dmrPgAccountId.pgName : null} disabled={true} required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr></hr>
                                                    <h4 className="form_title mt-4">Razorpay PG Keys {!this.state.editMode ? <i className="zmdi zmdi-edit" onClick={() => { this.setState({ editMode: true }) }} style={{ color: "#40189D", fontSize: 20, paddingLeft: 15, marginTop: 10 }}></i> : null}</h4>
                                                    <div className="row">
                                                        <div className="col-lg-6 mb-2">
                                                            <div className="form-group">
                                                                <label className="text-label">Grocery Payment Key ID*</label>
                                                                <input type="text" name="gpKeyid" className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("gpKeyid", e.target.value)} value={adminData ? adminData.gpKeyid : null} disabled={!this.state.editMode} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 mb-2">
                                                            <div className="form-group">
                                                                <label className="text-label">Grocery Payment Key Secret*</label>
                                                                <input type="text" name="gpKeySecret" className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("gpKeySecret", e.target.value)} value={adminData ? adminData.gpKeySecret : null} disabled={!this.state.editMode} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 mb-2">
                                                            <div className="form-group">
                                                                <label className="text-label">Institutional Payment Key ID*</label>
                                                                <input type="text" name="ipKeyid" className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("ipKeyid", e.target.value)} value={adminData ? adminData.ipKeyid : null} disabled={!this.state.editMode} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 mb-2">
                                                            <div className="form-group">
                                                                <label className="text-label">Institutional Payment Key Secret*</label>
                                                                <input type="text" name="ipKeySecret" className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("ipKeySecret", e.target.value)} value={adminData ? adminData.ipKeySecret : null} disabled={!this.state.editMode} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 mb-2">
                                                            <div className="form-group">
                                                                <label className="text-label">Rental Payment Key ID*</label>
                                                                <input type="text" name="rpKeyid" className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("rpKeyid", e.target.value)} value={adminData ? adminData.rpKeyid : null} disabled={!this.state.editMode} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 mb-2">
                                                            <div className="form-group">
                                                                <label className="text-label">Rental Payment Key Secret*</label>
                                                                <input type="text" name="rpKeySecret" className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("rpKeySecret", e.target.value)} value={adminData ? adminData.rpKeySecret : null} disabled={!this.state.editMode} required />
                                                            </div>
                                                        </div>
                                                        {this.state.editMode ?
                                                            <div className="col-lg-12 mt-2 mb-2 text-center">
                                                                <button type="button" onClick={this.handleOnUpdateCharges} className="btn btn-primary btn-block" disabled={this.state.isUpdatingPgKeys}>{this.state.isUpdatingPgKeys ? "Updating..." : "Update"}</button>
                                                            </div>
                                                            : null}
                                                    </div>
                                                    <hr></hr>
                                                    <h4 className="form_title mt-4">EasyBuzz PG Keys {!this.state.editModeEb ? <i className="zmdi zmdi-edit" onClick={() => { this.setState({ editModeEb: true }) }} style={{ color: "#40189D", fontSize: 20, paddingLeft: 15, marginTop: 10 }}></i> : null}</h4>
                                                    <div className="row">
                                                        <div className="col-lg-6 mb-2">
                                                            <div className="form-group">
                                                                <label className="text-label">Grocery Payment Key ID*</label>
                                                                <input type="text" name="ebGpKeyid" className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("ebGpKeyid", e.target.value)} value={adminData ? adminData.ebGpKeyid : null} disabled={!this.state.editModeEb} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 mb-2">
                                                            <div className="form-group">
                                                                <label className="text-label">Grocery Payment Key Secret*</label>
                                                                <input type="text" name="ebGpKeySecret" className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("ebGpKeySecret", e.target.value)} value={adminData ? adminData.ebGpKeySecret : null} disabled={!this.state.editModeEb} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 mb-2">
                                                            <div className="form-group">
                                                                <label className="text-label">Institutional Payment Key ID*</label>
                                                                <input type="text" name="ebIpKeyid" className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("ebIpKeyid", e.target.value)} value={adminData ? adminData.ebIpKeyid : null} disabled={!this.state.editModeEb} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 mb-2">
                                                            <div className="form-group">
                                                                <label className="text-label">Institutional Payment Key Secret*</label>
                                                                <input type="text" name="ebIpKeySecret" className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("ebIpKeySecret", e.target.value)} value={adminData ? adminData.ebIpKeySecret : null} disabled={!this.state.editModeEb} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 mb-2">
                                                            <div className="form-group">
                                                                <label className="text-label">Rental Payment Key ID*</label>
                                                                <input type="text" name="ebRpKeyid" className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("ebRpKeyid", e.target.value)} value={adminData ? adminData.ebRpKeyid : null} disabled={!this.state.editModeEb} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 mb-2">
                                                            <div className="form-group">
                                                                <label className="text-label">Rental Payment Key Secret*</label>
                                                                <input type="text" name="ebRpKeySecret" className="form-control" onChange={(e) => this.handleOnChangeMerchantChargesFields("ebRpKeySecret", e.target.value)} value={adminData ? adminData.ebRpKeySecret : null} disabled={!this.state.editModeEb} required />
                                                            </div>
                                                        </div>
                                                        {this.state.editModeEb ?
                                                            <div className="col-lg-12 mt-2 mb-2 text-center">
                                                                <button type="button" onClick={this.handleOnUpdateCharges} className="btn btn-primary btn-block" disabled={this.state.isUpdatingPgKeys}>{this.state.isUpdatingPgKeys ? "Updating..." : "Update"}</button>
                                                            </div>
                                                            : null}
                                                    </div>
                                                </section>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="manageAccounts" tabindex="-1" role="dialog" aria-labelledby="manageAccountsLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-3 mb-3" id="exampleModalLabel">Razorpay Accounts <a className="bg-primary text-light p-2 rounded ml-2" data-toggle="modal" data-target="#addAccount" style={{ cursor: 'pointer' }}>+ Add New</a></h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Name</th>
                                            <th>Number</th>
                                            <th>PG</th>
                                            <th>Key Id</th>
                                            <th>Secret</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.accountsList && this.state.accountsList.length > 0 ? this.state.accountsList.map((item, index) => (
                                            <tr style={adminData && adminData.dmrPgAccountId && adminData.dmrPgAccountId._id === item._id ? { backgroundColor: 'rgba(64, 24, 157, 0.5)', color: 'black' } : null}>
                                                <td>{index + 1}</td>
                                                <td>{item.accountName ? item.accountName : '-'}</td>
                                                <td style={{ wordWrap: 'break-word', minWidth: '160px', maxWidth: '160px' }}>{item.dmrAccountNumber ? item.dmrAccountNumber : '-'}</td>
                                                <td>{item.pgName ? item.pgName : '-'}</td>
                                                <td style={{ wordWrap: 'break-word', minWidth: '160px', maxWidth: '160px' }}>{item.dmrKeyid ? item.dmrKeyid : '-'}</td>
                                                <td style={{ wordWrap: 'break-word', minWidth: '160px', maxWidth: '160px' }}>{item.dmrKeySecret ? item.dmrKeySecret : '-'}</td>
                                                <td className="d-flex justify-content-around">
                                                    {!(adminData && adminData.dmrPgAccountId && adminData.dmrPgAccountId._id === item._id) ?
                                                        <>
                                                            {this.state.isDeletingAccount ?
                                                                <div id="preloader">
                                                                    <div class="sk-three-bounce">
                                                                        <div class="sk-child sk-bounce1"></div>
                                                                        <div class="sk-child sk-bounce2"></div>
                                                                        <div class="sk-child sk-bounce3"></div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <a style={{ cursor: 'pointer' }} onClick={() => { this.handleDeleteAccount(item._id) }}><i class="zmdi zmdi-delete" style={{ color: 'red', fontSize: '24px' }}></i></a>
                                                            }
                                                            {this.state.isUpdatingPgKeys ?
                                                                <div id="preloader">
                                                                    <div class="sk-three-bounce">
                                                                        <div class="sk-child sk-bounce1"></div>
                                                                        <div class="sk-child sk-bounce2"></div>
                                                                        <div class="sk-child sk-bounce3"></div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <a style={{ cursor: 'pointer' }} onClick={() => { this.handleOnSelectAccount(item) }}><i class="zmdi zmdi-check" style={{ color: 'green', fontSize: '24px' }}></i></a>
                                                            }
                                                        </>
                                                        : null}
                                                </td>
                                            </tr>
                                        )) : null}
                                    </tbody>
                                </table>
                            </div>
                            {/* <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary">Save changes</button>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="addAccount" tabindex="-1" role="dialog" aria-labelledby="addAccountLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Add Account</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form onSubmit={(e) => { e.preventDefault(); this.handleOnCreateNewAccount(); }}>
                                    <label>Payment Gateway</label>
                                    <select className="form-control" onChange={(e) => { this.handleOnChangeNewAccountFields("pgName", e.target.value) }}>
                                        <option value="" selected disabled hidden>-- select --</option>
                                        <option value="razorpay">Razorpay</option>
                                        <option value="easebuzz">Easebuzz</option>
                                    </select>
                                    {this.state.newAccount.pgName ?
                                        <>
                                            <label>Account Name</label>
                                            <input type="text" className="form-control" placeholder="Enter account name" onChange={(e) => { this.handleOnChangeNewAccountFields("accountName", e.target.value) }} />
                                            {this.state.newAccount.pgName === "razorpay"
                                                ?
                                                <>
                                                    <label>Account Number</label>
                                                    <input type="text" className="form-control" placeholder="Enter account number" onChange={(e) => { this.handleOnChangeNewAccountFields("dmrAccountNumber", e.target.value) }} />
                                                </>
                                                : null}
                                            <label>Key Id</label>
                                            <input type="text" className="form-control" placeholder="Enter key id" onChange={(e) => { this.handleOnChangeNewAccountFields("dmrKeyid", e.target.value) }} />
                                            <label>Key Secret</label>
                                            <input type="text" className="form-control" placeholder="Enter key secret" onChange={(e) => { this.handleOnChangeNewAccountFields("dmrKeySecret", e.target.value) }} />
                                            <button className="btn btn-primary btn-block mt-3" disabled={this.state.isCreatingNewAccount}>{this.state.isCreatingNewAccount ? "Creating..." : "Create"}</button>
                                        </>
                                        : null}
                                </form>
                            </div>
                            <button type="button" class="btn btn-secondary d-none" id="addAccountModal" data-dismiss="modal">Close</button>
                            {/* <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary">Save changes</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Pgkeys;