import React, { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { connect } from "react-redux";

import Login from './screens/common/Login';
import Error from './screens/Error';
import AdminDashboard from './screens/admin/AdminDashboard';
import CustomerUtilityTransactions from './screens/customer/UtilityTransactions';
import CustomerBillingTransactions from './screens/customer/BillingTransactions';
import MerchantBillingTransactions from './screens/merchant/BillingTransactions';
import MerchantUtilityTransactions from './screens/merchant/UtilityTransactions';
import WalletTransactions from './screens/merchant/WalletTransactions';
import CustomerProfile from './screens/customer/CustomerProfile';
import MerchantProfile from './screens/merchant/MerchantProfile';
// import { getisAuthenticated } from "./modules/reducers";
import { getisAuthenticated } from "./modules/reducers";
import MerchantList from './screens/merchant/MerchantList';
import CustomerList from './screens/customer/CustomerList';
import Tickets from './screens/admin/Tickets';
import CustomerCharges from './screens/admin/CustomerCharges';
import PgKeys from './screens/admin/PgKeys';
import AdminInfo from './screens/admin/AdminInfo';

export const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  }).format(value);

class App extends Component {
  constructor() {
    super();
    this.state = {
    }
  }
  componentDidMount() {
    window.onpopstate = e => {
      window.location.reload();
    }
  }

  render() {
    return (
      <BrowserRouter forceRefresh={true}>
        <div>
          {/* <Navigation /> */}
          <Switch>
            <Route path="/" component={Login} exact />
            {
              this.props.isAuthenticated ?
                <div>
                  <Route path="/customer/profile/:id" component={CustomerProfile} exact />
                  <Route path="/merchant/profile/:id" component={MerchantProfile} exact />
                  <Route path="/dashboard" component={AdminDashboard} exact />
                  <Route path="/customer/utility/transactions" component={CustomerUtilityTransactions} exact />
                  <Route path="/customer/billing/transactions" component={CustomerBillingTransactions} exact />
                  <Route path="/merchant/utility/transactions" component={MerchantUtilityTransactions} exact />
                  <Route path="/merchant/billing/transactions" component={MerchantBillingTransactions} exact />
                  <Route path="/wallet/transactions" component={WalletTransactions} exact />
                  <Route path="/admin/info" component={AdminInfo} exact />
                  <Route path="/merchant/list" component={MerchantList} exact />
                  <Route path="/customer/list" component={CustomerList} exact />
                  <Route path="/tickets/list" component={Tickets} exact />
                  <Route path="/customer/charges" component={CustomerCharges} exact />
                  <Route path="/admin/pg/keys" component={PgKeys} exact />
                </div>
                : null
            }
            <Route component={Error} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: getisAuthenticated(state)
  }
}

export default connect(mapStateToProps)(App);