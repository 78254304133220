import {
    LOGIN_USER,
    LOGGING_IN_USER,
    LOGOUT_USER,
    POPULATE_ERROR,
    LOGGING_OUT_USER
} from "./actions";

let initialState = {
    error: "",
    isError: false,
    isLogging: false,
    isLoggingOut: false,
    user: {},
    isAuthenticated: false,
}
function dataReducer(state = initialState, action) {
    switch (action.type) {
        case POPULATE_ERROR:
            return { ...state, error: action.error, isError: true }
        case LOGIN_USER:
            return { ...initialState, user: action.userData, isAuthenticated: action.isAuthenticated, isError: false };
        case LOGGING_IN_USER:
            return { ...state, isLogging: action.status, isError: false }
        case LOGOUT_USER:
            return { ...initialState };
        case LOGGING_OUT_USER:
            return { ...initialState, isLoggingOut: action.status, isError: false };
        default:
            return state;
    }
}

export default dataReducer;

export const getUserData = state => state.user;
export const getLoggingStatus = state => state.isLogging;
export const getisAuthenticated = state => state.isAuthenticated;
export const getErrorStatus = state => state.isError;
export const getErrorMessage = state => state.error;
export const getLogoutStatus = state => state.isLoggingOut;
